import React from "react";
import {Card, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import cart from './Cart.module.css'
import MoreButton from "../Buttons/More/More";
import { ICardData } from "../../types/redux_types";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";

const Cart: React.FC<ICardData> = ({
                                       card_type, name, api_key,
                                       url, stream_id, measurement_id,
                                       modify_date, id, user_id, create_date
                                   }) => {

    return (
        <>
            <Box
                // sx={{ minWidth:357,
                //     // '150%',
                // spacing:1 }}
            >
                <Card variant="outlined">
                    {/*<Grid container spacing={{ xs: 2, md: 3 }} >*/}
                    {/*    <Grid item xs={12} sm={6} md={4}>*/}
                    {/*<CardContent*/}
                    {/*    // sx={{ flex: '1 0 auto' }}*/}
                    {/*>*/}
                    <div style={{ display: 'flex', marginTop:'16px' }}>
                        <MoreButton cardId={id} />
                        <Typography style={{ fontFamily: 'Montserrat', color: 'black' }} component="div" variant="h5">
                            {name}
                        </Typography>
                    </div>

                    <div style={{ textAlign: 'justify', margin: "16px" }}>
                        <Typography style={{ fontFamily: 'Montserrat', color: 'black' }} variant="h6">
                            <NavLink to={url} style={{ color: '#07B7AD' }}>Ссылка на сайт</NavLink>
                        </Typography>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6">
                            <span style={{ color: 'black' }}>API Key:</span> <span style={{ color: 'grey' }}>{api_key}</span>
                        </Typography>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6">
                            <span style={{ color: 'black' }}>Measurement ID:</span> <span style={{ color: 'grey' }}>{measurement_id}</span>
                        </Typography>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6">
                            <span style={{ color: 'black' }}>Дата:</span> <span style={{ color: 'grey' }}>{modify_date}</span>
                        </Typography>

                        <Button style={{ fontFamily: 'Montserrat', borderRadius: '20px' }} variant="outlined">
                            <NavLink style={{ color: '#07B7AD', textDecoration: 'none' }} to={'/conversions/' + id.toString()}>мои конверсии</NavLink>
                        </Button>
                    </div>


                    {/*</CardContent>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} sm={2} md={4}></Grid>*/}
                    {/*</Grid>*/}
            </Card>
        </Box>
        </>
    );
}

export default Cart;
