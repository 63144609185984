
export const licenseJSON: {
    img: string;
    tariff: string;
    description: string;
    price: string;
    paymentLink: string;
}[] = [
    {
        img: 'string',
        tariff: 'Карта РФ',
        description: 'Оплатите любой картой РФ и возможность добавлять неограниченое количество конверсий ваша',
        price: '500 руб/мес',
        paymentLink: 'https://www.connection-arrow.com/payments'
    },
    {
        img: 'string',
        tariff: 'Зарубежная карта',
        description: 'Оплатите любой картой зарубежного банка и возможность добавлять неограниченое количество конверсий ваша',
        price: '7€/мес',
        paymentLink: 'https://stripe_payments/create_new_payment'
    },
];