import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { insertNewCard } from "../../../asyncActions/insertNewCard";
import { getCard } from "../../../asyncActions/getCard";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {NavLink} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import CreateIcon from "@mui/icons-material/Create";
import {cardUpdate} from "../../../asyncActions/cardUpdate";
import {insertConversion} from "../../../asyncActions/insertConversion";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";

export default function EditButton({cardId}: {cardId: string}) {
    const dispatch = useAppDispatch()
    const cardsArray = useAppSelector(state => state.card.cards_array);
    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [api, setApi] = useState('');
    const [measurement, setMeasurement] = useState('');
    const [cardType, setCardType] = useState(1);

    useEffect(() => {
        const card = cardsArray.find((card) => card.id === Number(cardId));
        if (card) {
            setName(card.name);
            setUrl(card.url);
            setApi(card.api_key);
            setMeasurement(card.measurement_id);
        }
    }, [cardId, cardsArray]);
    const handleName = (e: React.ChangeEvent<any>) => {
        if (e.target.value != undefined){setName(e.target.value)}
    }

    const handleUrl = (e: React.ChangeEvent<any>) => {
        if (e.target.value != undefined){setUrl(e.target.value)}
    }

    const handleApi = (e: React.ChangeEvent<any>) => {
        if (e.target.value != undefined){setApi(e.target.value)}
    }

    const handleMeasurement = (e: React.ChangeEvent<any>) => {
        if (e.target.value != undefined){setMeasurement(e.target.value)}
    }

    const handleEditOnClick = async () => {
        dispatch(cardUpdate(cardId, cardType,name, api, measurement, url));
        dispatch(getCard())
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <MenuItem onClick={handleClickOpen}> <CreateIcon/> Изменить </MenuItem>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Заполните форму"}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '50ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            error={name.length>50}
                            onChange={handleName}
                            helperText={name.length>50 && "Слишком длинное название. Оно может иметь только 50 символов"}
                            value={name}
                            required id="outlined-basic"
                            label="Название сайта"
                            variant="outlined"
                        />

                        <TextField
                            error={url.length>100}
                            onChange={handleUrl}
                            helperText={url.length>100 && "Слишком длинное название. Оно может иметь только 100 символов"}
                            value={url}
                            required id="outlined-basic"
                            label="URL"
                            variant="outlined" />

                        <TextField
                            error={api.length>50}
                            onChange={handleApi}
                            helperText={api.length>50 && "Слишком длинное название. Оно может иметь только 50 символов"}
                            value={api}
                            required id="outlined-basic"
                            label="API Ключ"
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <NavLink to='/help/api_key'>
                                                <HelpOutlineIcon style={{ color: '#07B7AD' }} />
                                            </NavLink>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            error={measurement.length > 50}
                            onChange={handleMeasurement}
                            helperText={measurement.length > 50 && "Слишком длинное название. Оно может иметь только 50 символов"}
                            value={measurement}
                            required
                            id="outlined-basic"
                            label="measurement_id"
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <NavLink to='/help/measurement_id'>
                                                <HelpOutlineIcon style={{ color: '#07B7AD' }} />
                                            </NavLink>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={name.length>50 || url.length>100 || api.length>50 || measurement.length>50 ||
                            name.length === 0 || url.length === 0 || api.length ===0 || measurement.length ===0 }
                        onClick={handleEditOnClick} color="primary" autoFocus
                    >
                        Редактировать
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}