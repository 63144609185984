import React, {useEffect, useState} from "react";
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {DeleteSharp} from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import {deleteCard} from "../../../asyncActions/deleteCard";
import {useAppDispatch} from "../../../hooks/redux";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Delete from "../Delete/Delete";
import DeleteButton from "../Delete/Delete";
import Button from "@mui/material/Button";
import AddButton from "../Add/Add";
import EditButton from "../Edit/EditButton";
import {getCard} from "../../../asyncActions/getCard";
import {getUser} from "../../../asyncActions/get_user";

interface cardProps {
    cardId: number
}

 const MoreButton: React.FC<cardProps> = ({cardId}) => {

    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [remove, setRemove] = useState('');
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [isEditing, setIsEditing] = useState(false);

     const handleToggle = () => {
         setOpen((prevOpen) => !prevOpen);
     };

     const handleClose = (event: Event | React.SyntheticEvent) => {
         if (
             anchorRef.current &&
             anchorRef.current.contains(event.target as HTMLElement)
         ) {
             return;
         }

         setOpen(false);
     };

     function handleListKeyDown(event: React.KeyboardEvent) {
         if (event.key === 'Tab') {
             event.preventDefault();
             setOpen(false);
         } else if (event.key === 'Escape') {
             setOpen(false);
         }
     }

     // return focus to the button when we transitioned from !open -> open
     const prevOpen = React.useRef(open);
     React.useEffect(() => {
         if (prevOpen.current === true && open === false) {
             anchorRef.current!.focus();
         }

         prevOpen.current = open;
     }, [open]);


    return (
        <Stack>
            <div>
                <IconButton
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <DeleteButton cardId={cardId}/>
                                        <EditButton cardId={cardId.toString()}/>
                                        {/*<MenuItem > <CreateIcon/> Изменить </MenuItem>*/}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Stack>
    )
}

export default MoreButton;