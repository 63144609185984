import React from 'react';
import { CssBaseline, Box, Grid} from "@mui/material";
import { Helmet } from "react-helmet-async";
import MenyTytorial from "./menyTytorial/MenyTytorial";
import TutorialBlock from "../../Components/TutorialBlock/TutorialBlock";
import {tutorial_list_JSON, tutorialJSON} from "./tutorialJSON";
import TutorialBlock_list from "../../Components/TutorialBlock/TutorialBlock_list";

const Tutorial = () => {

    const tutorialBlock: JSX.Element[] = tutorialJSON.map((e, index) => (
        <TutorialBlock
            key={index}
            headline={e.headline}
            description={e.description}
            video={e.video}
        />
    ));

    const tutorialBlockLst: JSX.Element[] = tutorial_list_JSON.map((e, index) => (
        <TutorialBlock_list key={index} headline={e.headline} description={e.description}  list_1={e.list_1}
                            list_2={e.list_2} list_3={e.list_3} list_4={e.list_4} list_5={e.list_5} list_6={e.list_6} video={e.video}/>
    ));


    return (
        <>
            <Helmet>
                <title>Документация Conversion Arrow</title>
                <meta name='description' content='оффлайн конверсия, google client id, Google Analytics, Гугл аналитика, интернет-маркетинг, маркетинг, товары в интернете, услуги в интернете, типы конверсий' />
            </Helmet>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    // flexDirection: 'row',
                    // minHeight: '100vh',
                    // flexGrow: 1
                }}
            >
                <Grid item xs={12} sm={2} md={3}>
                    <MenyTytorial/>
                </Grid>
                <div>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={2} md={1}></Grid>
                        <Grid item xs={11} sm={6} md={6}>
                            <h1 style={{fontFamily:'Montserrat'}}>Добро пожаловать на страницу с документацией Conversion Arrow</h1>
                            <p style={{fontFamily:'Montserrat'}}> Если вы маркетолог или предприниматель и вы продаете как онлайн, так и оффлайн ,
                                то этот сервис для вас. Conversion Arrow позволит вам вносить оффлайн конверсии без головной боли и заморочек.</p>
                            <p style={{fontFamily:'Montserrat'}}>В этом разделе представлена подробная документация того, как пользоваться нашим сервисом.</p>
                        </Grid>
                        <Grid item xs={12} sm={2} md={1}></Grid>
                    </Grid>

                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={2} md={1}></Grid>
                        <Grid item xs={11} sm={6} md={6}>
                            <h2 style={{fontFamily:'Montserrat'}}>Три причины использовать Conversion Arrow:</h2>
                            <li style={{fontFamily:'Montserrat'}}>Простота в использовании: Не нужно вести отдельную статистику или учить языки
                                программирования для интеграции конверсии в Google Analytics.
                                Наше приложение позволяет добавить оффлайн конверсию всего в пару кликов.</li>
                            <li style={{fontFamily:'Montserrat'}}>Удобный интерфейс: Благодаря быстрой регистрации и интуитивно понятному
                                интерфейсу приложение становится простым и доступным даже для пользователей
                                без глубоких знаний в аналитике.</li>
                            <li style={{fontFamily:'Montserrat'}}>Полная картина для маркетингового исследования: С использованием Conversion Arrow
                                вы сможете собирать всю необходимую информацию для эффективного маркетинга и
                                улучшения результатов продвижения.</li>
                        </Grid>
                        <Grid item xs={12} sm={2} md={1}></Grid>
                    </Grid>

                    {tutorialBlockLst[0]}

                    {tutorialBlock[0]}
                    {tutorialBlock[1]}
                    {tutorialBlock[2]}

                </div>
            </Box>
        </>
    );
};

export default Tutorial;
