import React, { ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { NavLink } from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import { registerUser } from '../../asyncActions/register_user';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import {CssBaseline, useMediaQuery} from '@mui/material';
import {Helmet} from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import {getUser} from "../../asyncActions/get_user";
import {LoginSlice} from "../../redux/loginReducer";
export default function RegisterPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [login, setLogin] = useState('');
    const [emailDirty, setEmailDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [emailError, setEmailError] = useState('Email can not be empty');
    const [passwordError, setPasswordError] = useState('Password can not be empty');
    const [isChecked, setIsChecked] = useState(false);
    const [isError, setError] = useState(0);
    const nextPage = useAppSelector(state => state.user.nextPage);
    const isNeedsNavigate = useAppSelector(state => state.user.isNeedsNavigate);

    const handleEmail = (e: React.ChangeEvent<any>) => {
        setEmailDirty(true);
        setEmail(e.target.value);
    };

    const handleLogin = (e: React.ChangeEvent<any>) => {
        setLogin(e.target.value);
    };

    const handlePassword = (e: React.ChangeEvent<any>) => {
        setPasswordDirty(true);
        setPassword(e.target.value);
    };

    // const handleLoginOnClick = () => {
    //     dispatch(registerUser(email, login, password));
    //     setTimeout(() => {
    //         navigate('/my_projects');
    //     }, 1000);
    // };

    const handleRegisterOnClick = async () => {
        dispatch(registerUser(email, login, password))
        // await dispatch(getUser())
        // setTimeout(() => {
        //     navigate("/my_projects");
        // }, 1000);
    }
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    useEffect(() => {
        if(isNeedsNavigate === 1){
            if(nextPage == "/my_projects"){
                setError(0)
                setTimeout(() => {
                    navigate(nextPage);
                    dispatch(LoginSlice.actions.setNeedsNavigate(0));
                }, 1000);
            } else{
                setError(1)
                dispatch(LoginSlice.actions.setNeedsNavigate(0));
            }
        }
    },[isNeedsNavigate]);

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Helmet>
                <title>Зарегестрироваться Conversion Arrow</title>
                <meta name='description' content='оффлайн конверсия, оффлайн событие, эффективные продажи, добавление конверсий,
                 Google Analytics, Гугл аналитика, маркетинг, товары в интернете, услуги в интернете, лицензирование для оффлайн конверсий'/>
            </Helmet>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    flexGrow: 1,
                }}
            >
                <div style={{border: '1px solid #07B7AD', width: '25em', height:'40em',  margin: 'auto',borderRadius:'20px'}}>
                <Stack spacing={2} alignItems='center'>
                    <div style={{ marginTop:'90px' }}>
                        <Typography
                            style={{fontWeight: 500, textAlign: 'center', fontFamily:'Montserrat' }}
                            variant='h4'
                        >
                            Зарегистрируйтесь здесь
                        </Typography>
                    </div>

                    <TextField
                        style={{fontFamily:'Montserrat' }}
                        label="Имя"
                        error={login.length > 20}
                        helperText={login.length > 20 && 'Слишком длинное название. Оно может иметь только 20 символов'}
                        value={login}
                        required
                        onChange={handleLogin}
                    />


                    <TextField
                        style={{fontFamily:'Montserrat' }}
                        value={email}
                        label="Email"
                        // error={emailDirty && email.length === 0}
                        // helperText={emailDirty && email.length === 0 && 'Email не может быть пустым'}
                        onChange={handleEmail}
                        required
                    />

                    <TextField
                        style={{fontFamily:'Montserrat' }}
                        error={passwordDirty && password.length > 20 }
                        helperText={passwordDirty && password.length > 20 && 'Слишком длинный пароль. Максимум 20 символов'}
                        label="Пароль"
                        value={password}
                        required
                        onChange={handlePassword}
                        type="password"
                    />

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox onChange={handleCheckboxChange} {...label} />
                        <Typography style={{fontFamily:'Montserrat' }} variant="body2">
                            Нажимая на кнопку "Зарегистрироваться", вы соглашаетесь с
                            <NavLink style={{fontFamily:'Montserrat' }} to={'/termsofuse'}> политикой конфиденциальности</NavLink>
                        </Typography>
                    </div>

                    <Button
                        onClick={handleRegisterOnClick}
                        style={{
                            borderRadius: '20px',
                            fontFamily: 'Montserrat',
                            background: isChecked || login.length > 20 &&
                            login.length === 0 &&
                            emailDirty && email.length === 0 &&
                            passwordDirty && password.length > 20 &&
                            passwordDirty && password.length === 0 ?
                                'linear-gradient(180deg, #26DDD4, #07B7AD)' : '#BBBBBB'
                        }}
                        disabled={
                            login.length > 20 ||
                            login.length === 0 ||
                            emailDirty && email.length === 0 ||
                            passwordDirty && password.length > 20 ||
                            passwordDirty && password.length === 0 ||
                            !isChecked
                        }
                        variant="contained"
                        endIcon={<SendIcon />}
                    >
                        {/*<NavLink style={{color:'#fff', textDecoration:'none'}} to='/my_projects'>*/}
                        Зарегистрироваться
                        {/*</NavLink>*/}
                    </Button>

                    <div>
                        <Typography variant="body2" style={{fontFamily:'Montserrat' }}>
                            Уже есть аккаунт? <NavLink style={{fontFamily:'Montserrat' }}
                                                       to="/login">Войдите в свой профиль здесь</NavLink>
                        </Typography>
                    </div>
                </Stack>
                </div>
            </Box>
        </>
    );
}
