import {backendUrl, siteOrigin} from "../globalConfig";

export const deleteConversion = async (conversionId: number) => {
    return await fetch(backendUrl+ '/conversions/conversion_delete/' + conversionId.toString(), {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then((r) =>  {
            if(r.status==200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
}