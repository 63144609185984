import React from "react";
import {Grid, Typography, useMediaQuery} from "@mui/material";

interface Itutorial_list {
    headline: string;
    description: string;
    list_1: string;
    list_2: string;
    list_3: string;
    list_4: string;
    list_5: string;
    list_6: string;
    video: string;
}
const TutorialBlock: React.FC<Itutorial_list> = ({headline, description, video,  list_1,
                                                     list_2, list_3, list_4, list_5, list_6}) => {

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <div style={{ marginTop: "20px"}}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={2} md={1}></Grid>
                    <Grid item xs={11} sm={6} md={6}>
                        <Typography variant='h5'style={{fontFamily:'Montserrat',fontWeight:'600' }}>{headline}</Typography>
                        <p style={{fontFamily:'Montserrat'}}>{description}</p>
                        <li style={{fontFamily:'Montserrat'}}>{list_1}</li>
                        <li style={{fontFamily:'Montserrat'}}>{list_2}</li>
                        <li style={{fontFamily:'Montserrat'}}>{list_3}</li>
                        <li style={{fontFamily:'Montserrat'}}>{list_4}</li>
                        <li style={{fontFamily:'Montserrat'}}>{list_5}</li>
                        <li style={{fontFamily:'Montserrat'}}>{list_6}</li>
                        {isMobile ? (
                            <iframe
                                src={video}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <iframe
                                style={{ width: 450, height: 250 }}
                                src={video}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={2} md={1}></Grid>
                </Grid>
            </div>
        </>
    );
}

export default TutorialBlock