import React, {useEffect, useState, ReactNode} from "react";
import {CardContent, CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';

import Typography from "@mui/material/Typography";
import {NavLink} from "react-router-dom";
import Button from "@mui/material/Button";

import {StrongsJSON} from "../../Components/Strongs/StrongsJSON";
import Strongs from "../../Components/Strongs/Strongs";
import { motion } from "framer-motion"
import {useAppSelector} from "../../hooks/redux";
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from '@mui/material';

export default function MainPage0 () {
    let pint_3 = "/img/cursor.png"
    let video_back = "/img/1.png"
    let video_back_2 = "/img/2.png"
    let video_back_3 = "/img/3.png"
    let pint = "/img/03.png"
    let pint1 = "/img/04.png"
    const user_email = useAppSelector(state => state.user.email)

    let strong: JSX.Element[] = StrongsJSON.map((e) => <Strongs img={e.img}
                                                                elementsArray={e.elements_array} />)

    let v_link : ReactNode | null = null;
        if (user_email){
            v_link = <NavLink style={{ color:'#fff', textDecoration: 'none', fontFamily:'Montserrat'}} to='/my_projects'>
                Создать конверсию
            </NavLink>
        }
        else
            v_link = <NavLink style={{ color:'#fff', textDecoration: 'none', fontFamily:'Montserrat'}} to="/register">
                Создать конверсию </NavLink>

    const isMobile = useMediaQuery('(max-width:600px)');
    const isIpad = useMediaQuery('(max-width:834px)');
    const isIpad1 = useMediaQuery('(max-width:834px)');

    const [animate, setAnimate] = useState(false);
    const [animationPlayed, setAnimationPlayed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.getElementById("about-section");
            if (element && !animationPlayed) {
                const rect = element.getBoundingClientRect();
                const viewHeight = window.innerHeight || document.documentElement.clientHeight;
                // Check if the top of the element is within the viewport
                if (rect.top <= viewHeight * 0.75) {
                    setAnimate(true);
                    setAnimationPlayed(true);
                    // Remove event listener once animation has played
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [animationPlayed]);

    return(
        <>
            <Helmet>
                <title>Conversion Arrow</title>
                <meta name='description' content='оффлайн конверсия, эффективные продажи, увелечение прибыли, Google Analytics,
                Гугл аналитика, интернет-маркетинг, маркетинг, товары в интернете, услуги в интернете, лицензирование для оффлайн конверсий' />
            </Helmet>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >
                <div style={{marginTop: isMobile ? '0' : "16px"}}>
                    <Grid container spacing={{ xs: 2, md: 3 }} style={{ background: 'linear-gradient(180deg, #07B7AD, white)'}} >
                        <Grid item xs={1} sm={1} md={1}></Grid>
                        <Grid item xs={10} sm={5} md={5}>
                            <div style={{paddingTop: isMobile ? '2em' : '8em'}}>
                                <motion.div
                                    initial={{ x: -200, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 1 }}>
                                <Typography style={{ fontWeight: 500, textAlign: 'left', fontFamily:'Jura',
                                    color:'#3f5e5d'}} variant="h2">
                                    Добавь оффлайн конверсию в Google Analytics за 2 клика!
                                </Typography>
                                </motion.div>
                            <div>
                                <div style={{display:'flex'}}>

                                <motion.div
                                    initial={{ x: -200, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 1 }}
                                >
                                {!isMobile ?
                                <Button style={{ textAlign: 'left', backgroundColor: '#3f5e5d',
                                    marginTop:'1em', borderRadius: 20,
                                opacity:0.7, width:'300px', height:'70px'}}
                                variant="contained">
                                    {v_link}
                                </Button>
                                    :
                                    <Button style={{ textAlign: 'center', backgroundColor: '#3f5e5d',
                                        marginTop:'1em', borderRadius: 20,
                                        opacity:0.7, width:'300px', height:'60px'}}
                                            variant="contained">
                                        {v_link}
                                    </Button>
                                }
                                </motion.div>

                                <motion.div initial={{ y: -200, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            transition={{ duration: 2 }}>
                                    <motion.div
                                        animate={{ y: [-10, 10, -10],
                                        transition: { duration: 3, repeat: Infinity, repeatType: 'reverse' } }}
                                        style={{paddingTop:'2em', paddingLeft:'1em'}}
                                    >

                                    {!isMobile && <img src={pint_3} alt="pint" width={50} height={50} />}

                                    </motion.div>
                                </motion.div>
                                </div>

                                </div>

                            </div>
                        </Grid>

                        <Grid item xs={10} sm={5} md={3}>
                            <div style={{display:'flex'}}>
                                {!isMobile && (
                                <motion.div initial={{ y: 200, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            transition={{ duration: 2 }}>
                                    <motion.div
                                        animate={{ y: [-10, 10, -10], transition: { duration: 2, repeat: Infinity, repeatType: 'reverse' } }}
                                        style={{ paddingTop: '10em' }}
                                    >
                                        <img style={{ position: 'relative', transform: 'scaleX(-1)' }}
                                             src={pint_3} alt="pint" width={90} height={90} />
                                    </motion.div>
                                </motion.div>
                                )}
                                <motion.div initial={{ x: 200, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1 }}
                                            transition={{ duration: 1 }}>
                                {!isMobile ?
                                <img src={pint} style={{ maxWidth: "600px" }} />
                                : <img src={pint} style={{ maxWidth: "400px", textAlign: 'center'}} />    }
                                </motion.div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={1} md={3}></Grid>
                    </Grid>
                </div>


                <div style={{marginTop: "250px", textAlign: 'left',fontFamily:'Montserrat' }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} >
                        <Grid item xs={1} sm={3} md={2}></Grid>
                        <Grid item xs={10} sm={3} md={4}>
                            <motion.div
                                initial={{ y: -200, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 1 }}
                            >
                            <Typography style={{ fontWeight: 500, textAlign: 'left', marginTop:'3em'}}
                                        variant='h4'><span>Что такое</span><br />
                                <span>Conversion Arrow?</span></Typography>
                                <p>Если вы активный пользователь Google Analytics - этот сервис для вас.
                                    Conversion Arrow позволит вам вносить оффлайн конверсии <strong>в два клика</strong> без головной боли и
                                    гадания на кофейной гуще. Все что нужно: привезать гугл аналитику к сайту, заполнить
                                    необходими данными поля и ваша конверсия внесена!
                                </p>
                            </motion.div>
                        </Grid>
                        <Grid item xs={10} sm={3} md={4}>
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 1 }}
                            >
                            <img width="90%" height="90%"  src={pint1}/>
                            </motion.div>
                        </Grid>
                        <Grid item xs={1} sm={3} md={2}></Grid>
                    </Grid>
                </div>

                <div style={{ marginTop: "300px" }}>

                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={1} sm={1} md={2}></Grid>
                        <Grid item xs={10} sm={10} md={3}>
                            <motion.div
                                initial={{ y: -200, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 1 }}
                            >
                            <Typography style={{ fontWeight: 500, fontFamily: 'Montserrat', marginTop: "1em" }} variant='h4'>
                                Для чего нужен<br />
                                Conversion Arrow?
                            </Typography>

                            <p style={{ fontFamily: 'Montserrat' }}>
                                Есть 2 способа добавления оффлайн  конверсий в Google Analytics.
                                Первый — с помощью средств Google, а второй — с помощью программного запроса.
                                В обоих случаях возникают сложности. Используя средства Google,
                                загрузить файлы без ошибок практически невозможно. Второй способ не подойдет,
                                если вы ничего не понимаете в программировании. Именно поэтому появился
                                <strong> Conversion Arrow</strong>, чтобы вы могли добавить конверсию <strong>в
                                два клика</strong>.
                            </p>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sm={10} md={5} style={{ position: 'relative', display: 'flex',
                            justifyContent: 'center', alignItems: 'center' }}>
                            <motion.img
                                initial={{ x: 100, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 1 }}
                                src={video_back}
                                style={{
                                    width: '70%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: '2%',
                                    left: '10%',
                                    right: 0,
                                    bottom: 0,
                                }}
                            />
                            <motion.div
                                animate={{ y: [-10, 10, -10], transition: { duration: 3, repeat: Infinity, repeatType: 'reverse' } }}
                                style={{
                                    position: 'absolute',
                                    top: isMobile ? '-20%' : '-10%',
                                    left: '10%',
                                }}
                            >
                                <motion.img
                                    initial={{ x: 100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 1 }}
                                    style={{
                                        width:  '50%',
                                        height: '30%',
                                    }}
                                    src={video_back_2}
                                />
                            </motion.div>
                            <motion.div
                                animate={{ x: [10, -10, 10], transition: { duration: 3, repeat: Infinity, repeatType: 'reverse' } }}
                                style={{
                                    position: 'absolute',
                                    top: isMobile ? '50%' : '40%',
                                    left: '30%',
                                }}
                            >
                                <img
                                    src={video_back_3}
                                    style={{
                                        width:  '70%',
                                        height: '70%',
                                    }}
                                />
                            </motion.div>
                            <motion.iframe
                                initial={{ x: 100, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 1 }}
                                style={{
                                    width: '90%',
                                    height: '300px',
                                    borderRadius: '10px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                src="https://www.youtube.com/embed/ftnJIzJxo10"
                                frameBorder="0"
                                allowFullScreen
                            ></motion.iframe>

                        </Grid>

                        <Grid item xs={1} sm={1} md={2}></Grid>
                    </Grid>
                </div>

                <motion.div
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1 }}
                    style={{ textAlign: 'center', marginTop:'300px', marginBottom: '100px'}}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={2} md={3}></Grid>
                        <Grid item xs={12} sm={2} md={6}>
                            <Typography style={{fontWeight: 500, textAlign: 'center', fontFamily:'Montserrat'}}
                                        variant='h4'>Наши преимущества</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={3}></Grid>

                        <Grid item xs={1} sm={2} md={2}></Grid>
                            <Grid item xs={10} sm={2} md={4}>
                                {strong[0]}
                            </Grid>

                            {isMobile && (
                                <Grid item xs={1} sm={2} md={2}></Grid>
                            )}

                        {isMobile && (
                            <Grid item xs={1} sm={2} md={2}></Grid>
                        )}

                        <Grid item xs={10} sm={2} md={4}>
                                {strong[1]}
                            </Grid>
                            {isMobile && (
                                <Grid item xs={1} sm={2} md={2}></Grid>
                            )}

                        <Grid item xs={1} sm={2} md={2}></Grid>
                    </Grid>
                </motion.div>

            </Box>
        </>
    )
}