import React, {ReactNode, useEffect} from "react";
import DehazeIcon from '@mui/icons-material/Dehaze';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {NavLink, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getUser} from "../../asyncActions/get_user";
import {logoutUserAsync} from "../../asyncActions/logout_user";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from '@mui/material/Toolbar';
import HeaderLogin from "./Header_login/Header_login";
import HeaderLogout from "./Header_logout/Header_logout";
import Paper from "@mui/material/Paper";
// import logo from '../../../public/img/logo2.svg'
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {CssBaseline} from "@mui/material";
import { useMediaQuery } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        marginTop: theme.spacing(1),
        minWidth: 180,
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                marginRight: theme.spacing(1.5),
            },
        },
    },
}));

export function Header() {
    let logo = "/img/logo2.svg"
    const dispatch = useAppDispatch();
    const user_email = useAppSelector(state => state.user.email);
    const license_id = useAppSelector((state) => state.user.license_id);
    const user_data = useAppSelector(state => state.user.conversions_limit);
    const license_end_datetime = useAppSelector((state) => state.user.license_end_datetime);
    const isMobile = useMediaQuery('(max-width:600px)');
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        dispatch(getUser());
    }, []);

    const navigate = useNavigate();

    function f_logout() {
        dispatch(logoutUserAsync());
        setTimeout(() => {
            navigate("/");
        }, 1000);
    }

    let v_login_str: ReactNode | null = null;
    if (user_email) {
        v_login_str = <HeaderLogin />
        ;
    } else {
        v_login_str = <HeaderLogout />;
    }

    let v_button: ReactNode | null = null;

    if (user_email && license_id === 1) {
        if (!isMobile) {
            v_button = <p> Осталось {user_data} конверсий</p>;
        }
    } else if (user_email && license_id === 2) {
        if (!isMobile) {
        v_button = <p>Лицензия действует до {license_end_datetime}</p>;
        }
    } else {
        v_button = (
            <div>
                <Button variant="outlined" style={{ borderColor: 'white', borderRadius: 20 }}>
                    <NavLink style={{ textDecoration: 'none', color: 'white', fontFamily:'Montserrat' }} to="/login">
                        Войти
                    </NavLink>
                </Button>

                {!isMobile &&
                    <Button variant="outlined" style={{ borderColor: 'white', marginLeft:'1em',
                        borderRadius: 20,fontFamily:'Montserrat' }}>
                        <NavLink style={{ textDecoration: 'none', color: 'white' }} to="/register">
                            Регистрация
                        </NavLink>
                    </Button>
                }
            </div>
        );
    }


    return (
        <Box sx={{ flexGrow: 1, width:'100%'}}>
            <AppBar position="static" >
                <Toolbar style={{backgroundColor:'#07B7AD'}}>
                    <Button
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        disableElevation
                        onClick={handleClick}
                    >
                    <DehazeIcon
                        style={{color:'#fff'}}
                        viewBox='0 0 20 20'
                        fontSize="large"
                    />
                    </Button>

                    <NavLink style={{textDecoration:'none'}}  to='/'>
                        <img
                            style={{width:210, height:70 }}
                            src={logo}/>
                    </NavLink>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    {v_button}
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <Paper sx={{ maxWidth: '100%' }}>
                            <MenuItem onClick={handleClose} disableRipple>
                                {v_login_str}
                            </MenuItem>
                        </Paper>
                    </StyledMenu>
                </Toolbar>
            </AppBar>
        </Box>

    );
}
