import {AppDispatch} from "../redux/store";
import {siteOrigin, backendUrl} from "../globalConfig";
import {cardsSlice} from "../redux/cardReducer";

export const getCard = () => async (dispatch: AppDispatch) => {
    fetch(backendUrl + '/card/get_user_cards', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then((r) =>  {
            if(r.status==200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
        .then((data) => {
            if (data.status ==200){
                dispatch(cardsSlice.actions.cardsFetching(data.json))
            }
        })
        .catch((e) => {
            console.log('Error: ' + e.message);
        })
}
