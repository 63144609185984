import React from "react";
import Grid from "@mui/material/Grid";
export default function EmptyConversion() {

    return (
        <div style={{color: 'darkgray', textAlign: 'center',fontFamily:'Montserrat' }} >
            <Grid container spacing={{ xs: 2, md: 3 }}  >
                <Grid item xs={12} sm={2} md={1}></Grid>
                <Grid item xs={12} sm={8} md={12}>
                    <h1>Здесь пока ничего нет</h1>
                    <p>Чтобы добавить конверсий нажмите на кнопку "добавить конверсию" или перетащите csv файл</p>
                </Grid>
                <Grid item xs={12} sm={2} md={1}></Grid>
            </Grid>
        </div>
    )
}