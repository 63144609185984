import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import { createSvgIcon } from '@mui/material/utils';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {Dialog, DialogTitle, DialogActions, DialogContent, Autocomplete} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { IConversion } from "../../../types/redux_types";
import { insertConversion } from "../../../asyncActions/insertConversion";
import { getUser } from '../../../asyncActions/get_user';
import {getCard} from "../../../asyncActions/getCard";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {NavLink} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
export interface IConversionProps {
    cardId: number;
    conversionArray: IConversion[];
    setConversionArray: Dispatch<SetStateAction<IConversion[]>>;
}


const AddConversion: React.FC<IConversionProps> = ({ cardId, conversionArray, setConversionArray }) => {
    const user_data = useAppSelector(state => state.user.conversions_limit);
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [conversionValue, setConversionValue] = React.useState('')
    const [clientId, setClientId] = React.useState('');
    const [click, setClick] = React.useState(0);
    const license_id = useAppSelector(state => state.user.license_id);
    const conversions_limit = useAppSelector(state => state.user.conversions_limit);
    const [selectedCurrency, setSelectedCurrency] = React.useState<{ label: string } | null>(null);

    const PlusIcon = createSvgIcon( // иконка плюса
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
        </svg>,
        'Plus',
    );

    useEffect(() => {
        dispatch(getCard());
        dispatch(getUser());
    }, []);


    const handleName = (e: React.ChangeEvent<any>) => { //имя
        setName(e.target.value);
    };


    const handleClientId = (e: React.ChangeEvent<any>) => { //клиент ид
        setClientId(e.target.value);
    };


    const handleConversionValue = (e: React.ChangeEvent<any>) => { //значение
        setConversionValue(e.target.value);
    };


    const handleClickOpen = () => { //закрытие и открытие окна
        setOpen(true);
    };


    const handleClose = () => { //закрытие
        setOpen(false);
    };

    const [showAlert, setShowAlert] = useState(false);

    const handleAddOnClick = async () => {
        // if (license_id === 1 && click <= 5) {
        //     return;
        // }
        const newConversion = await insertConversion(cardId, clientId, name, conversionValue, selectedCurrency?.label || '');


        if (newConversion != null) {
            setConversionArray([...conversionArray, newConversion]);
            setClick((prevClick) => prevClick + 1);
            setOpen(false);
            setShowAlert(true);
        }
        // <Alert  severity="success"
        //     // onClose={() => setShowAlert(true)}
        // >
        //     <AlertTitle>Офлайн конверсия успешно добавлена!</AlertTitle>
        //     Через 24-48 часов вы сможете увидеть ее в Google Analytics!
        // </Alert>
    };


    // useEffect(() => {
    //     if (license_id === 1 && click >=5) {
    //         handleAddOnClick();
    //     }
    // }, []);


    let v_license_alert = null;  // - ограничение по количеству карточек


    if (license_id === 1 && conversions_limit == 0) {
        v_license_alert = (
            <Alert  severity="error"
                // onClose={() => setShowAlert(true)}
            >
                <AlertTitle>Бесплатные конверсии закончились!</AlertTitle>
                Преобретите <NavLink style={{fontWeight:600, color:'black'}} to='/license'>лицензию</NavLink> , либо ждите следующего месяца!
            </Alert>
        );
    }


    useEffect(() => {
        const storedClick = localStorage.getItem(`click_${license_id}`);
        if (storedClick) {
            setClick(parseInt(storedClick, 10));
        }
    }, [license_id]);


    useEffect(() => {
        dispatch(getUser());
        localStorage.setItem(`click_${license_id}`, click.toString());
    }, [click, license_id]);


    const handleCurrencyChange = (event: React.SyntheticEvent<Element, Event>, newValue: { label: string } | null) => {
        setSelectedCurrency(newValue); // выбор валюты
    };


    let v_license_str: ReactNode | null = null;


    const currency= [ // список валюты
        { label: 'USD'},
        { label: 'EUR'},
        { label: 'RUS'},
    ];


    return (
        <>
            <Button style={{borderRadius:'20px', fontFamily:'Montserrat',
                background: 'linear-gradient(180deg, #26DDD4, #07B7AD)' }} variant="contained" onClick={handleClickOpen}>
                <PlusIcon/>добавить конверсию
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Заполните форму"}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': {m: 1, width: '50ch'},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            style={{fontFamily:'Montserrat' }}
                            error={name.length > 50}
                            onChange={handleName}
                            helperText={name.length > 50 && "Слишком длинное название. Оно может иметь только 50 символов"}
                            required
                            id="outlined-basic"
                            label="Название конверсии"
                            variant="outlined"
                        />
                        <TextField
                            style={{fontFamily:'Montserrat' }}
                            error={name.length > 50}
                            onChange={handleConversionValue}
                            helperText={conversionValue.length > 50 && "Слишком длинное название. Оно может иметь только 50 символов"}
                            required
                            id="outlined-basic"
                            label="Значение конверсии"
                            variant="outlined"
                        />
                        <Autocomplete
                            style={{fontFamily:'Montserrat' }}
                            disablePortal
                            id="combo-box-demo"
                            onChange={handleCurrencyChange} // Update the state with the selected currency
                            value={selectedCurrency} // Set the selected value
                            options={currency}
                            getOptionLabel={(option) => option.label} // Provide a function to get the label from the options
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Валюта" />}
                        />
                        <TextField
                            style={{fontFamily:'Montserrat' }}
                            error={clientId.length > 50}
                            onChange={handleClientId}
                            helperText={clientId.length > 50 && "Слишком длинный Client id. Он может иметь только 50 символов"}
                            required
                            id="outlined-basic"
                            label="Client ID"
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <NavLink to='/help/client_id'>
                                                <HelpOutlineIcon style={{ color: '#07B7AD' }} />
                                            </NavLink>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {v_license_alert}
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{fontFamily:'Montserrat' }}
                        onClick={handleAddOnClick}
                        disabled={
                            user_data <= 0 ||
                            name.length > 50 ||
                            clientId.length > 50 ||
                            name.length === 0 ||
                            clientId.length === 0
                        }
                    >
                        добавить
                    </Button>
                </DialogActions>
            </Dialog>
            {v_license_str}
            <Snackbar
                open={showAlert}
                autoHideDuration={3000}
                onClose={() => setShowAlert(false)}
            >
                <Alert onClose={() => setShowAlert(false)} severity="success">
                    <AlertTitle>Оффлайн конверсия успешно добавлена!</AlertTitle>
                    Через 24-48 часов вы сможете увидеть ее в Google Analytics!
                </Alert>
            </Snackbar>
        </>
    );
}


export default AddConversion;
