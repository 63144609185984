import React, { useEffect, useState } from 'react';
import { CssBaseline, Box, Typography, Tabs, Tab, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { IPaymentHistory } from "../../types/redux_types";
import { getUserAllPayments, getUserPaidPayments } from "../../asyncActions/getUserPayment";
import EmptyHistory from "../../Components/Empty/EmptyHistory";

export default function HistoryPage() {
    const [value, setValue] = useState<number>(0);
    const [allPaymentsArray, setAllPaymentsArray] = useState<IPaymentHistory[]>([]);
    const [successedPaymentsArray, setSuccessedPaymentsArray] = useState<IPaymentHistory[]>([]);

    const localArray = value === 0 ? allPaymentsArray : successedPaymentsArray;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            const allPayments = await getUserAllPayments();
            setAllPaymentsArray(allPayments);
            const paidPayments = await getUserPaidPayments();
            setSuccessedPaymentsArray(paidPayments);
        }
        fetchData();
    }, []);

    const getStatusDisplayText = (status: string) => {
        switch (status) {
            case 'canceled':
                return 'отменен';
            case 'open':
                return 'открытый статус';
            case 'expired':
                return 'истекший';
            case 'complete':
                return 'завершенный';
            default:
                return status;
        }
    };

    const getStatusDisplayTextPaymentStatus = (paymentStatus: string) => {
        switch (paymentStatus) {
            case 'false':
                return 'отменен';
            case 'unpaid':
                return 'не оплачен';
            case 'paid':
                return 'оплачен';
            case 'complete':
                return 'завершенный';
            default:
                return paymentStatus;
        }
    };

    const getCurrency = (currency: string) => {
        switch (currency) {
            case 'eur':
                return 'EUR';
            case 'rub':
                return 'RUB';
            default:
                return currency;
        }
    };

    return (
        <>
            <Helmet>
                <title>История платежей</title>
                <meta name='description' content='оффлайн конверсия, оффлайн событие, эффективные продажи, добавление конверсий,
                 Google Analytics, Гугл аналитика, маркетинг, товары в интернете,
                 услуги в интернете, лицензирование для оффлайн конверсий'/>
            </Helmet>
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', flexGrow: 1 }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab style={{ fontFamily: 'Montserrat' }} label="Все платежи" />
                            <Tab style={{ fontFamily: 'Montserrat' }} label="Успешные платежи" />
                        </Tabs>
                    </Box>
                    <Typography style={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: 500 }} variant="h3">
                        История платежей
                    </Typography>
                    {localArray.length > 0 ? (
                        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 600, fontFamily: 'Montserrat' }} align="center">Тип оплаты</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontFamily: 'Montserrat' }} align="center">Сумма</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontFamily: 'Montserrat' }} align="center">Валюта</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontFamily: 'Montserrat' }} align="center">Статус</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontFamily: 'Montserrat' }} align="center">Платёжный статус</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontFamily: 'Montserrat' }} align="center">Дата</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontFamily: 'Montserrat', maxWidth: 200 }} align="center">URL</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {localArray.map((e) => (
                                        <TableRow key={e.id}>
                                            <TableCell style={{ fontFamily: 'Montserrat' }} align="center">{e.payment_type}</TableCell>
                                            <TableCell style={{ fontFamily: 'Montserrat' }} align="center">{e.amount}</TableCell>
                                            <TableCell style={{ fontFamily: 'Montserrat' }} align="center">{getCurrency(e.currency)}</TableCell>
                                            <TableCell style={{ fontFamily: 'Montserrat' }} align="center"
                                                       sx={{
                                                           backgroundColor: e.status === 'complete' ? "#53CB58" :
                                                               e.status === 'open' ? "#FFD700" :
                                                                   e.status === 'canceled' ? "#CB5353" :
                                                                       e.status === 'expired' ? "#FFA07A" : "#CB5353"
                                                       }}>{getStatusDisplayText(e.status)}</TableCell>
                                            <TableCell style={{ fontFamily: 'Montserrat' }} align="center"
                                                       sx={{ backgroundColor: e.is_paid === 0 ? "#CB5353" : "#53CB58" }}>
                                                {getStatusDisplayTextPaymentStatus(e.payment_status)}</TableCell>
                                            <TableCell style={{ fontFamily: 'Montserrat' }} align="center">{e.modify_date}</TableCell>
                                            <TableCell style={{ fontFamily: 'Montserrat', maxWidth: 200 }} align="center">
                                                <NavLink target="_blank" to={e.url}>ссылка на платёж</NavLink></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <EmptyHistory />
                    )}
                </Box>
            </Box>
        </>
    );
}
