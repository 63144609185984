import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { setupStore } from "./redux/store";
import { createTheme, ThemeProvider } from "@mui/material";
import { yellow, teal, grey } from '@mui/material/colors';
import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://fab2712618664942aeecd739b90f1bf6@sentry.digitalberd.com/5",
    // integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
});

const store = setupStore();

const outerTheme = createTheme({
    palette: {
        primary: teal,
        secondary: grey,
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <ThemeProvider theme={outerTheme}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </ThemeProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
