import { siteOrigin, backendUrl } from "../globalConfig";
import { AppDispatch } from "../redux/store";
import { LoginSlice } from "../redux/loginReducer";
import { cardsSlice } from "../redux/cardReducer";
import {useParams} from "react-router-dom";

interface CardUpdateData {
    // card_id: string;
    card_type: number;
    name: string;
    api_key: string;
    measurement_id: string;
    url: string;
}

export const cardUpdate = (cardId: string, card_type:number, name: string, apiKey: string, measurementId: string, url: string) => async (dispatch: AppDispatch) => {
    const updatedCardData: CardUpdateData = {
        name: name || '',
        card_type: 1,
        api_key: apiKey || '',
        measurement_id: measurementId || '',
        url: url || ''
    };

    fetch(backendUrl + '/card/card_update/'+ Number(cardId), {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedCardData)
    })
        .then((r) =>  {
            if(r.status==200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
        .then((data) => {
            if (data.status ==200){
                dispatch(cardsSlice.actions.cardUpdate(data.json))
            }
        })
        .catch((e) => {
            console.log('Error: ' + e.message);
        })
}
