import React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { NavLink } from "react-router-dom";
import LicenseIcon from "@mui/icons-material/AssignmentInd";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from '@mui/material';
import HelpIcon from "@mui/icons-material/Help";

export default function HeaderLogout() {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <MenuList>
                <MenuItem>
                    <ListItemIcon>
                        <LocalLibraryIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <NavLink style={{ color: 'black', textDecoration: 'none',fontFamily:'Montserrat' }} to='/help'>
                            Подсказка
                        </NavLink>
                    </ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <LicenseIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <NavLink style={{ color: 'black', textDecoration: 'none', fontFamily:'Montserrat' }} to='/license'>
                            Лицензия
                        </NavLink>
                    </ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <HelpIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <NavLink style={{color:'black', textDecoration:'none', fontFamily:'Montserrat'}}
                                 to='https://t.me/+76LDbKMIDL04NDcy'>
                            Задать вопрос в Telegram
                        </NavLink>
                    </ListItemText>
                </MenuItem>
                {isMobile && (
                    <>
                        <Divider />
                        <Button variant="contained" style={{ borderColor: 'white', borderRadius:20, fontFamily:'Montserrat',
                            opacity:0.7, background: 'linear-gradient(180deg, #26DDD4, #07B7AD)'}}>
                            <NavLink style={{ textDecoration: 'none', color: 'white' }} to="/register">
                                Регистрация
                            </NavLink>
                        </Button>
                    </>
                )}
            </MenuList>
        </>
    )
}
