import {AppDispatch} from "../redux/store";
import {siteOrigin, backendUrl} from "../globalConfig";

export const getUserAllPayments = async () => {
    return await fetch(backendUrl+'/payments/get_user_all_payments' , {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(r => r.json())
}

export const getUserPaidPayments = async () => {
    return await fetch(backendUrl+'/payments/get_user_paid_payments' , {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(r => r.json())
}




