export const tutorialJSON:{
    headline: string;
    description: string;
    video: string;
}[] = [
    {
        headline: 'Как создать карточку проекта?',
        description: 'Прежде чем создать проект, убедитесь, что вы подключили Google Analytics к сайту! ' +
            'Если этот пункт выполнен, необходимо перейти на страницу "Мои проекты". Здесь находим кнопку "Добавить проект", ' +
            'заполняем поля данными (название проекта, URL, API key, measurement ID), убеждаемся, что всё правильно, и затем' +
            ' нажимаем на "Добавить". Проекты можно создавать неограниченное количество раз, вне зависимости от того,' +
            ' приобрели вы лицензию или нет.',
        video: 'https://www.youtube.com/embed/pax6qBNTKSE',
    }, //0
    {
        headline: 'Как добавлять одну конверсию?',
        description: 'Прежде чем создать конверсию, перейдем в Google Analytics и создадим событие (в видео подробнее все показано).' +
            ' После вернемся в Conversion Arrow в "Мои проекты", находим нужную карточку с проектом и нажимаем "Мои конверсии". ' +
            'Здесь мы выбираем "Добавить конверсию". Заполняем данные (название, значение, валюту и client ID). ' +
            'После нажатия на кнопку "Добавить" нужно ждать 24 часа, чтобы добавились конверсии. Без лицензии вам доступно добавление ' +
            '5 конверсий в месяц.',
        video: 'https://www.youtube.com/embed/d9BRbqLypHo',
    },//1
    {
        headline: 'Как добавлять несколько конверсий?',
        description: 'Прежде чем создать конверсию, перейдем в Google Analytics и создадим событие (в видео подробнее все показано). ' +
            'После вернемся в Conversion Arrow в "Мои проекты", находим нужную карточку с проектом и нажимаем "Мои конверсии". ' +
            'Здесь мы находим синюю рамочку. Именно через нее мы будем добавлять конверсии. Для того чтобы они добавились в' +
            ' приложение, необходимо, чтобы конверсии были в файле формата CSV (как и чем заполнять можно посмотреть, ' +
            'кликнув по кнопке "Пример файла CSV"). После прохождения 24 часов наши конверсии будут добавлены в Google Analytics. ' +
            'Без лицензии вам доступно добавление 5 конверсий в месяц.',
        video: 'https://www.youtube.com/embed/i5sgJjPBMjY',
    },//2
    {
        headline: 'Где взять API key?',
        description: 'Для этого переходим в наш проект в Google Analytics. ' +
            'Нажимаем вкладку "Администратор". В администраторе нам нужна вкладка "Сбор и редактирование данных",' +
            ' где затем нужно нажать на "Потоки данных". В потоках данных нам отобразятся все потоки на сайт или приложение.' +
            ' Мы выбираем нужный из списка поток и находим "Секретный ключ API для Measurement Protocol". ' +
            'Далее мы нажимаем кнопку "Создать". Здесь нам нужно придумать псевдоним. И когда он отобразится в списке,' +
            ' все, что нам останется - это скопировать ключ и вставить в карточку с новым проектом.',
        video: 'https://www.youtube.com/embed/nMBhZpNxJrU',
    },//3
    {
        headline: 'Где взять client id?',
        description: 'Чтобы найти идентификатор, необходимо зайти в Google Analytics и перейти в раздел "Исследования".' +
            ' Затем выберите "Статистика пользователей. Здесь вы сможете увидеть индикаторы клиента, ' +
            'указанные для каждого отдельного пользователя. Вы выбираете нужный и вставляете в карточку с конверсией',
        video: 'https://www.youtube.com/embed/1GNOKXUUZww',
    },//4
    {
        headline: 'Где взять Measuremente id?',
        description: 'Для этого заходим в наш проект в Google Analytics и переходим в раздел Администратор. ' +
            'Здесь выбираем "Поток данных". Находим нужный поток для нашего сайта или приложения. ' +
            'В деталях потока, в самом правом верхнем углу, мы видим идентификатор Measurement ID. ' +
            'Теперь вам остается его скопировать и добавить в карточку проекта.',
        video: 'https://www.youtube.com/embed/bhOqYwxrKD0',
    },//5
]


export const tutorial_list_JSON:{
    headline: string;
    description: string;
    list_1: string;
    list_2: string;
    list_3: string;
    list_4: string;
    list_5: string;
    list_6: string;
    video: string;
}[] = [
    {
        headline: 'Зачем нужны оффлайн конверсии?',
        description: '6 причин почему следует с ними работать',
        list_1:'Измерение полного воздействия кампании: Если ваша компания использует как онлайн, ' +
            'так и оффлайн каналы маркетинга (например, рекламу в интернете и традиционные рекламные кампании), ' +
            'оффлайн конверсии позволяют вам оценить полный эффект ваших маркетинговых усилий.',
        list_2: 'Атрибуция: Оффлайн конверсии помогают в атрибуции, т.е. определении того, какие\n' +
            'каналы маркетинга и рекламы привлекают пользователей, которые затем совершают оффлайн\n' +
            'конверсии. Это позволяет более точно определить ROI и эффективность различных каналов.',
        list_3: 'Оценка эффективности точек продаж: Если у вас есть несколько точек продаж или филиалов,\n' +
            'оффлайн конверсии помогут оценить и сравнить их эффективность. Вы можете определить,\n' +
            'какие места лучше привлекают и удерживают клиентов.',
        list_4: 'Оптимизация маркетинговых кампаний: Анализ оффлайн конверсий может помочь в\n' +
            'оптимизации рекламных кампаний, идентифицировать наиболее успешные каналы и оптимизировать\n' +
            'бюджеты в соответствии с реальным воздействием на продажи.',
        list_5: 'Сегментация аудитории: Оффлайн конверсии позволяют более детально анализировать аудиторию, ' +
            'учитывая как их онлайн, так и оффлайн поведение. Это может быть полезно для создания более точных ' +
            'маркетинговых стратегий и персонализации кампаний.',
        list_6: 'Оценка влияния сезонности: Анализ оффлайн конверсий может также помочь ' +
            'понять, как сезонные изменения и события влияют на покупательское поведение.',
        video: 'https://www.youtube.com/embed/D3oWEyDGtG8',
    },
]





