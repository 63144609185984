import React, {ReactNode, useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getUser } from '../../../asyncActions/get_user';
import {NavLink, useNavigate} from 'react-router-dom';
import { logoutUserAsync } from '../../../asyncActions/logout_user';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import HelpIcon from '@mui/icons-material/Help';
import LoginIcon from "@mui/icons-material/Login";
import LicenseIcon from '@mui/icons-material/AssignmentInd'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HeaderLogout from "../Header_logout/Header_logout";
import {useMediaQuery} from "@mui/material";

export default function HeaderLogin() {
    const dispatch = useAppDispatch();
    const user_email = useAppSelector((state) => state.user.email);
    const user_id = useAppSelector((state) => state.user.id);
    const license_end_datetime = useAppSelector((state) => state.user.license_end_datetime);
    const license_id = useAppSelector((state) => state.user.license_id);
    const nextPage = useAppSelector((state) => state.user.nextPage);
    const isMobile = useMediaQuery('(max-width:600px)');
    const user_data = useAppSelector(state => state.user.conversions_limit);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        dispatch(getUser());
    }, []);

    const navigate = useNavigate();

    function f_logout() {
        dispatch(logoutUserAsync());
        setTimeout(() => {
            navigate('/');
        }, 1000);
    }

    let v_license_str: ReactNode | null = null;
    if (license_id === 2) {
        v_license_str = <p style={{fontFamily:'Montserrat'}}>Лицензия действует до {license_end_datetime}</p>;
    }

    let v_login_str: ReactNode | null = null;
    if (user_email) {
        v_login_str = <HeaderLogin />
        ;
    } else {
        v_login_str = <HeaderLogout />;
    }

    let v_button: ReactNode | null = null;

    if (user_email && license_id === 1) {
        if (isMobile) {
            v_button = <p style={{fontFamily:'Montserrat'}}> Осталось {user_data} конверсий</p>;
        }
    } else if (user_email && license_id === 2) {
        if (isMobile) {
            v_button = <p style={{fontFamily:'Montserrat'}}>Лицензия действует до {license_end_datetime}</p>;
        }
    }

    return (
        <>
          {/*<Paper sx={{ width: 320, maxWidth: '100%' }}>*/}
              <MenuList>
                   <MenuItem>
                      <ListItemIcon>
                           <PersonIcon fontSize="small" />
                      </ListItemIcon>
                            <ListItemText>{user_email}</ListItemText>
                   </MenuItem>
                  <MenuItem>
                      <ListItemText>{v_button}</ListItemText>
                  </MenuItem>
                     <Divider />
                   <MenuItem>
                       <ListItemIcon>
                            <WorkIcon fontSize="small" />
                       </ListItemIcon>
                            <ListItemText>
                                <NavLink style={{color:'black', textDecoration:'none', fontFamily:'Montserrat'}} to='/my_projects'>
                                Мои проекты
                                </NavLink>
                            </ListItemText>
                        </MenuItem>
                  <MenuItem>
                      <ListItemIcon>
                          <MonetizationOnIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                          <NavLink style={{color:'black', textDecoration:'none',fontFamily:'Montserrat'}} to='/payments'>
                              Мои платежи
                          </NavLink>
                      </ListItemText>
                  </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <LocalLibraryIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink style={{color:'black', textDecoration:'none', fontFamily:'Montserrat'}} to='/help'>
                                Подсказка
                                </NavLink>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <LicenseIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink style={{color:'black', textDecoration:'none', fontFamily:'Montserrat'}} to='/license'>
                                    Лицензия
                                </NavLink>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <HelpIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink style={{color:'black', textDecoration:'none', fontFamily:'Montserrat'}}
                                         to='https://t.me/+76LDbKMIDL04NDcy'>
                                Задать вопрос в Telegram
                                </NavLink>
                            </ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={f_logout}>
                            <ListItemIcon>
                                <LoginIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText style={{fontFamily:'Montserrat'}}>
                                Выйти
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                {/*</Paper>*/}
        </>
    );
}
