import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import { loginUserAsync } from "../../asyncActions/login_user";
import { getUser } from "../../asyncActions/get_user";
import { CssBaseline, Box, Typography, TextField, Grid, Button } from "@mui/material";
import {isError} from "util";
import {LoginSlice} from "../../redux/loginReducer";
import {Helmet} from "react-helmet-async";

export default function LoginPage() {
    const user_error = useAppSelector(state => state.user.isError)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isError, setError] = useState(0);
    const nextPage = useAppSelector(state => state.user.nextPage);
    const isNeedsNavigate = useAppSelector(state => state.user.isNeedsNavigate);

    const handlePassword = (e: React.ChangeEvent<any>) => {
        if (e.target.value !== undefined){
            setPassword(e.target.value)
        }
    }

    const f_login = async () => {
        dispatch(loginUserAsync(email, password));
    };


    useEffect(() => {
        if(isNeedsNavigate === 1){
            if(nextPage == "/my_projects"){
                setError(0)
                setTimeout(() => {
                    navigate(nextPage);
                    dispatch(LoginSlice.actions.setNeedsNavigate(0));
                }, 1000);
            } else{
                setError(1)
                dispatch(LoginSlice.actions.setNeedsNavigate(0));
            }
        }
    },[isNeedsNavigate]);



    return (
        <>
            <Helmet>
                <title>Войти в Conversion Arrow</title>
                <meta name='description' content='оффлайн конверсия, оффлайн событие, эффективные продажи, добавление конверсий,
                 Google Analytics, Гугл аналитика, маркетинг, товары в интернете, услуги в интернете, лицензирование для оффлайн конверсий'/>
            </Helmet>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >
                <div style={{ border: '1px solid #07B7AD', width: '25em', height:'30em', margin: 'auto', borderRadius:'20px'}}>
                <div style={{ marginTop: "100px" }}>
                    <Typography style={{fontWeight: 500, textAlign: 'center', fontFamily:'Montserrat'}}
                                variant='h4'>Войдите в аккаунт</Typography>
                </div>


                <div style={{ marginTop: "10px", textAlign: 'center'}}>
                    <TextField
                        style={{fontFamily:'Montserrat'}}
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        label="Email"
                        required
                        helperText = {isError === 1 && "неверный логин."}
                        error = {isError === 1}
                    />
                </div>

                <div style={{ marginTop: "10px", textAlign: 'center' }}>
                    <Grid>
                        <TextField
                            style={{fontFamily:'Montserrat'}}
                            type="password"
                            helperText = {isError === 1 && "неверный пароль."}
                            error = {isError === 1}
                            value={password}
                            onChange={handlePassword}
                            id="password"
                            label="Пароль"
                            required
                        />
                    </Grid>
                    {/*<Grid>*/}
                    {/*    <div style={{ marginTop: "10px" }}>*/}
                    {/*        <NavLink style={{ textDecoration: 'none' }} to='/'>Забыли пароль?</NavLink>*/}
                    {/*    </div>*/}
                    {/*</Grid>*/}
                </div>

                <div style={{ marginTop: "10px", textAlign: 'center' }}>
                    <Button style={{borderRadius:'20px', fontFamily:'Montserrat',
                        background: 'linear-gradient(180deg, #26DDD4, #07B7AD)' }} onClick={() => f_login()} variant="contained">
                        Войти
                    </Button>
                </div>


                <div style={{ marginTop: "10px", textAlign: 'center' }}>
                    <p style={{fontFamily:'Montserrat'}}>Нет еще аккаунта? </p>
                    <NavLink style={{ textDecoration: 'none', fontFamily:'Montserrat'}} to="/register">Создайте аккаунт</NavLink>
                </div>
                </div>

            </Box>
        </>
    );
}