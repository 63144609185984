import React, { useState } from "react";
import Button from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "../../../hooks/redux";
import { insertNewCard } from "../../../asyncActions/insertNewCard";
import { getCard } from "../../../asyncActions/getCard";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {NavLink} from "react-router-dom";


export default function AddButton() {
    const dispatch = useAppDispatch()


    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [api, setApi] = useState('');
    const [measurement, setMeasurement] = useState('');


    const handleName = (e: React.ChangeEvent<any>) => {
        if (e.target.value != undefined){setName(e.target.value)}
    }


    const handleUrl = (e: React.ChangeEvent<any>) => {
        if (e.target.value != undefined){setUrl(e.target.value)}
    }


    const handleApi = (e: React.ChangeEvent<any>) => {
        if (e.target.value != undefined){setApi(e.target.value)}
    }


    const handleMeasurement = (e: React.ChangeEvent<any>) => {
        if (e.target.value != undefined){setMeasurement(e.target.value)}
    }




    const handleAddOnClick = async () => {
        dispatch(insertNewCard(name, api, measurement, url));
        // dispatch(getCard())
        setOpen(false);
    }




    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };




    const PlusIcon = createSvgIcon(
        // credit: plus icon from https://heroicons.com/
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 26 26"
            strokeWidth={1.5}
            stroke="currentColor"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
        </svg>,
        'Plus',
    );


    return (
        <>
            <Button style={{borderRadius:'20px', fontFamily:'Montserrat',
                background: 'linear-gradient(180deg, #26DDD4, #07B7AD)' }} variant="contained"
                    onClick={handleClickOpen}> <PlusIcon/>добавить проект</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{fontFamily:'Montserrat'}} id="alert-dialog-title">{"Заполните форму"}</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText id="alert-dialog-description">*/}
                    {/*</DialogContentText>*/}
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '50ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField error={name.length>50}
                                   style={{fontFamily:'Montserrat'}}
                                   onChange={handleName}
                                   helperText={name.length>50 && "Слишком длинное название. Оно может иметь только 50 символов"}
                                   value={name}
                                   required id="outlined-basic"
                                   label="Название сайта"
                                   variant="outlined" />

                        <TextField error={url.length>100}
                                   style={{fontFamily:'Montserrat'}}
                                   onChange={handleUrl}
                                   helperText={url.length>100 && "Слишком длинное название. Оно может иметь только 100 символов"}
                                   value={url}
                                   required id="outlined-basic"
                                   label="URL"
                                   variant="outlined" />


                        <TextField error={api.length>50}
                                   style={{fontFamily:'Montserrat'}}
                                   onChange={handleApi}
                                   helperText={api.length>50 && "Слишком длинное название. Оно может иметь только 50 символов"}
                                   value={api}
                                   required id="outlined-basic"
                                   label="API Ключ"
                                   variant="outlined"
                                   InputProps={{
                                       endAdornment: (
                                           <InputAdornment position="end">
                                               <IconButton>
                                                   <NavLink to='/help/api_key'>
                                                       <HelpOutlineIcon style={{ color: '#07B7AD' }} />
                                                   </NavLink>
                                               </IconButton>
                                           </InputAdornment>
                                       ),
                                   }}
                        />


                        <TextField
                            error={measurement.length > 50}
                            style={{fontFamily:'Montserrat'}}
                            onChange={handleMeasurement}
                            helperText={measurement.length > 50 && "Слишком длинное название. Оно может иметь только 50 символов"}
                            value={measurement}
                            required
                            id="outlined-basic"
                            label="measurement ID"
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <NavLink to='/help/measurement_id'>
                                                <HelpOutlineIcon style={{ color: '#07B7AD' }} />
                                            </NavLink>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>


                </DialogContent>
                <DialogActions>
                    <Button
                        style={{fontFamily:'Montserrat'}}
                        disabled={name.length>50 || url.length>100 || api.length>50 || measurement.length>50 ||
                            name.length === 0 || url.length === 0 || api.length ===0 || measurement.length ===0 }
                        onClick={handleAddOnClick} color="primary" autoFocus>
                        добавить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
