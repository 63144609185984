import React from "react";
import {Grid, Typography, useMediaQuery} from "@mui/material";

interface Itutorial {
    headline: string;
    description: string;
    video: string;
}
const TutorialBlock: React.FC<Itutorial> = ({headline, description, video}) => {

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
       <>
           <div
               style={{ marginTop: "16px", marginBottom:'20px'}}
           >
               <Grid container spacing={{ xs: 2, md: 3 }}>
                   <Grid item xs={12} sm={2} md={1}></Grid>
                   <Grid item xs={11} sm={6} md={4}>
                       <Typography style={{fontFamily:'Montserrat',fontWeight:'600' }} variant='h5'>{headline}</Typography>
                       <p style={{fontFamily:'Montserrat'}}>{description}</p>
                       {isMobile ? (
                           <iframe
                               src={video}
                               frameBorder="0"
                               allowFullScreen
                           ></iframe>
                       ) : (
                           <iframe
                               style={{ width: 450, height: 250 }}
                               src={video}
                               frameBorder="0"
                               allowFullScreen
                           ></iframe>
                       )}

                   </Grid>
                   <Grid item xs={12} sm={2} md={1}></Grid>
               </Grid>
           </div>
       </>
    );
}

export default TutorialBlock