import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPaymentHistoryState, IPaymentHistory} from "../types/redux_types";

export const initialState: IPaymentHistoryState = {
    payments_array: []
 }

 export const paymentSlice = createSlice({
     name: 'payment',
     initialState,
     reducers: {
         paymentFetching(state, action: PayloadAction<IPaymentHistory[]>){
             state.payments_array = action.payload;
         },
     }
 })
export default paymentSlice.reducer;

