import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MoreButton from "../More/More";
import {deleteCard} from "../../../asyncActions/deleteCard";
import {useAppDispatch} from "../../../hooks/redux";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from "@mui/material/Typography";
import deletebutton from './Delete.module.css'
import CustomizedSnackbars from "./Alert/Alert";

interface cardProps {
    cardId: number
}

 const DeleteButton: React.FC<cardProps> = ({cardId}) =>{
    const dispatch = useAppDispatch()

    const [open, setOpen] = React.useState(false);
    const [remove, setRemove] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <MenuItem onClick={handleClickOpen}> <DeleteIcon /> Удалить</MenuItem>
            <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
                <div>
            <DialogTitle id="alert-dialog-title">{"Вы уверены, что хотите удалить эту карточку?"}</DialogTitle>
                <Typography className={deletebutton.par}>После удаления данные навсегда будут утеряны</Typography>
            <DialogContent>
                <DialogContentText >
                    <Box>
                        <div className={deletebutton.button}>
                        <div className={deletebutton.but}>
                            <CustomizedSnackbars cardId={cardId}/>
                        </div>
                        <div className={deletebutton.but}>
                        <Button className={deletebutton.cancel} onClick={handleClose}>отменить</Button>
                        </div>
                    </div>
                    </Box>
                </DialogContentText>
            </DialogContent>
                </div>
        </Dialog>
      </>
    )
}

export default DeleteButton