import React, {useState, useRef, useEffect, Dispatch, SetStateAction} from "react";
import {IconButton, TextField} from "@mui/material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import {IConversion} from "../../types/redux_types";
import {getCardConversionsFilter} from "../../asyncActions/getCardConversionsFilter";
import {getConversion} from "../../asyncActions/getConversion";
import {useParams} from "react-router-dom";
import {getCardConversionsOneFieldFilter} from "../../asyncActions/getCardConversionsOneFieldFilter";

export interface IConversionProps {
    conversionArray: IConversion[];
    setConversionArray: Dispatch<SetStateAction<IConversion[]>>;
}

const SearchComponent: React.FC<IConversionProps> = ({ conversionArray, setConversionArray }) => {
    const fieldRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const params = useParams();

    const [conversionName, setConversionName] = useState<string>("");
    const [conversionValue, setConversionValue] = useState<string>("");
    const [currency, setCurrency] = useState<string>("");
    const [clientId, setClientId] = useState<string>("");
    const [beginDate, setBeginDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [mainString, setMainString] = useState<string>("")


    const handleClick = () => {
        setAnchorEl(fieldRef.current)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleConversionName = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setConversionName(e.target.value)}
    }

    const handleConversionValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setConversionValue(e.target.value)}
    }

    const handleCurrency = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setCurrency(e.target.value)}
    }

    const handleClientId = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setClientId(e.target.value)}
    }

    const handleMainString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setMainString(e.target.value)}
    }

    const genMainString = () => {
        if(conversionName != "" || conversionValue != "" || currency != "" || clientId != "" || beginDate != null || endDate != null) {
            let localString = '{'
            if (conversionName.length > 0) {
                localString += '"conversionName": "' + conversionName + '", '
            }
            if (conversionValue.length > 0) {
                localString += '"conversionValue": "' + conversionValue + '", '
            }
            if (currency.length > 0) {
                localString += '"currency": "' + currency + '", '
            }
            if (clientId.length > 0) {
                localString += '"conversionValue": "' + clientId + '", '
            }
            if (beginDate != null) {
                localString += '"beginDate": "' + beginDate.format('YYYY-MM-DD') + '", '
            }
            if (endDate != null) {
                localString += '"endDate": "' + endDate.format('YYYY-MM-DD') + '", '
            }
            localString = localString.substring(0, localString.length - 2) + '}'
            setMainString(localString)
        }else{setMainString('')}
    }

    const clearAll = async () => {
        setConversionName("")
        setConversionValue("")
        setCurrency("")
        setClientId("")
        setBeginDate(null)
        setEndDate(null)
        setMainString("")
        const data = await getConversion(Number(params.cardId));
        setConversionArray(data);
    }


    const handleOk = async (e: React.ChangeEvent<any>) => {
        setAnchorEl(null);
        let beginDateString = ''
        let endDateString = ''
        if(beginDate != null){
            beginDateString = beginDate.format('YYYY-MM-DD')
        }
        if(endDate != null){
            endDateString = endDate.format('YYYY-MM-DD')
        }
        if(conversionName != "" || conversionValue != "" || currency != "" || clientId != "" || beginDate != null || endDate != null){
            const data = await getCardConversionsFilter(Number(params.cardId), conversionName, currency, clientId, beginDateString, endDateString);
            setConversionArray(data);
        }else{
            const data = await getCardConversionsOneFieldFilter(Number(params.cardId), mainString);
            setConversionArray(data);
        }
    }

    const SearchButton = () => (
        <IconButton onClick={handleOk}>
            <SearchIcon />
        </IconButton>
    )

    const EndButtons = () => (
        <>
            <IconButton onClick={clearAll}>
                <ClearIcon />
            </IconButton>
            <IconButton onClick={handleClick}>
                <TuneIcon />
            </IconButton>
        </>
    )

    useEffect(()=>{
        genMainString();
    },[conversionName, conversionValue, currency, clientId, beginDate, endDate])

    return(
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TextField
                    sx={{ marginTop:'1em' }}
                    id="searchField"
                    ref={fieldRef}
                    label="Search"
                    value={mainString}
                    onChange={handleMainString}
                    InputProps={{
                        startAdornment: <SearchButton />,
                        endAdornment: <EndButtons />,
                        sx: { borderRadius: 10 }
                    }}
                />

                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'searchField',
                    }}
                    elevation={5}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <Box component="span"
                         sx={{
                             width: 250,
                             height: 120,
                         }}
                    >
                        <div style={{margin: "16px"}}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} sm={6} md={6}  key="giConversionName">
                                    <div style={{marginTop: "16px"}}>
                                        <TextField
                                            fullWidth
                                            value={conversionName}
                                            label="Название конверсии"
                                            onChange={handleConversionName}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}  key="giConversionValue">
                                    <div style={{marginTop: "16px"}}>
                                        <TextField
                                            fullWidth
                                            value={conversionValue}
                                            label="Значение"
                                            onChange={handleConversionValue}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}  key="giCurrency">
                                    <div style={{marginTop: "16px"}}>
                                        <TextField
                                            fullWidth
                                            value={currency}
                                            label="Валюта"
                                            onChange={handleCurrency}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}  key="giClientId">
                                    <div style={{marginTop: "16px"}}>
                                        <TextField
                                            fullWidth
                                            value={clientId}
                                            label="Client Id"
                                            onChange={handleClientId}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}  key="giBeginDate">
                                    <div style={{marginTop: "16px"}}>
                                        <DatePicker
                                            slotProps={{ textField: { fullWidth: true } }}
                                            label="Дата создания"
                                            value={beginDate}
                                            onChange={(newValue: Dayjs | null) => setBeginDate(newValue)}
                                        />
                                    </div>
                                </Grid>
                                {/*<Grid item xs={12} sm={6} md={6}  key="giEndDate">*/}
                                {/*    <div style={{marginTop: "16px"}}>*/}
                                {/*        <DatePicker*/}
                                {/*            slotProps={{ textField: { fullWidth: true } }}*/}
                                {/*            label="End Date"*/}
                                {/*            value={endDate}*/}
                                {/*            onChange={(newValue: Dayjs | null) => setBeginDate(newValue)}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</Grid>*/}

                            </Grid>

                            <div style={{marginTop: "16px", display: "flex", justifyContent: "right"}}>
                                <Button style={{ borderRadius:'20px', fontFamily:'Montserrat',
                                    background: 'linear-gradient(180deg, #26DDD4, #07B7AD)' }}
                                        variant="contained" onClick={handleOk}>Искать</Button>
                            </div>
                        </div>
                    </Box>
                </Menu>
            </LocalizationProvider>
        </>
    )
}

export default SearchComponent;