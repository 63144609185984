import {siteOrigin, backendUrl} from "../globalConfig";

export const getConversion = async (cardId: number) => {
    const params = new URLSearchParams({
        card_id: cardId.toString(),
    }).toString()
    return await fetch(backendUrl+'/conversions/get_card_conversions?' + params, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(r => r.json())
    // return resultArray
}


