import {siteOrigin, backendUrl} from "../globalConfig"
    ;

export const insertPaymentStripe = async () => {
    return await fetch(backendUrl+'/stripe_payments/create_new_payment', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            // amount: amount,
            // return_url: return_url,
            // description: description,
        })
    })

        .then(r => r.json())
}