import React from "react";
import Typography from "@mui/material/Typography";
import strongs from './Strongs.module.css';
import { motion } from "framer-motion"
import {useMediaQuery} from "@mui/material";
interface Element {
    head: string;
    text: string;
}

interface StrongsProps {
    img: string;
    elementsArray: Element[];
}

const Strongs: React.FC<StrongsProps> = ({ img, elementsArray }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <div style={{ paddingTop: '1em' }}>
                <img
                    width="90%"
                    height="90%"
                    src={img}
                    // alt="image"
                />
                {elementsArray.map((element, index) => (
                    <div key={index}
                         style={{ textAlign: isMobile ? 'left' : 'center'}}
                    >
                        <Typography style={{ fontWeight: 600,  fontFamily: 'Montserrat' }}>
                            {element.head}
                        </Typography>
                        <p style={{ fontFamily: 'Montserrat' }}>
                            {element.text}
                        </p>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Strongs;