import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {deleteCard} from "../../../../asyncActions/deleteCard";
import {useState} from "react";
import {useAppDispatch} from "../../../../hooks/redux";
import alert from './Alert.module.css';


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={5} ref={ref} variant="filled" {...props} />;
});


interface cardProps {
    cardId: number
}




const CustomizedSnackbars: React.FC<cardProps> = ({cardId}) =>{
    const dispatch = useAppDispatch()
    const [open, setOpen] = React.useState(false);
    const [remove, setRemove] = useState('');


    const handleClick = () => {
        setOpen(true);
        dispatch(deleteCard(cardId));
    };


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }


        setOpen(false);
    };


    const handleRemoveOnClick = async () => {
        dispatch(deleteCard(cardId));
    }


    return (
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Button className={alert.delete} onClick={handleClick} >удалить</Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '150%', height:'50%'}}>
                    Карточка успешно удалена
                </Alert>
            </Snackbar>
        </Stack>
    );
}


export default CustomizedSnackbars
