import React, {Dispatch, SetStateAction, useState} from 'react';
import {CssBaseline, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {ICreateNewPayment} from "../../types/redux_types";
import {Helmet} from "react-helmet-async";
import Button from "@mui/material/Button";
import {insertPayment} from "../../asyncActions/insertPayment";
// import license from "../../../public/img/license.png";
import CheckIcon from '@mui/icons-material/Check';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {NavLink} from "react-router-dom";
import {motion} from "framer-motion";
import {licenseJSON} from "./licenseJSON";
import LicenceCard from "../../Components/LicenceCard/LicenceCard";
// import pint from "../../../public/img/03.png";
export interface IPaymentProps {
    createNewPaymentArray: ICreateNewPayment[];
    setCreateNewPaymentArray: Dispatch<SetStateAction<ICreateNewPayment[]>>;
}

export default function License () {
    let license = "/img/license.png"
    let pint_3 = "/img/cursor.png"
    let video_back = "/img/1.png"
    let video_back_2 = "/img/2.png"
    let video_back_3 = "/img/3.png"
    let pint = "/img/03.png"
    let pint1 = "/img/04.png"


    let licenceCards: JSX.Element[]  = licenseJSON.map((e) => <LicenceCard img={e.img} tariff={e.tariff}
                                                                           description={e.description}
                                                                           price={e.price} paymentLink={e.paymentLink} /> )
    const handlePaymentCreate = async () => {
        const newPayment = await insertPayment(500.00, 'https://www.connection-arrow.com/payments', 'производим платеж')
        console.log(newPayment.redirect_link)
        window.location.href = newPayment.redirect_link;
    };

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Helmet>
                <title>Лицензия Conversion Arrow</title>
                <meta name='description' content='оффлайн конверсия, оффлайн событие, эффективные продажи, добавление конверсий,
                 Google Analytics, Гугл аналитика, маркетинг, товары в интернете, услуги в интернете, лицензирование для оффлайн конверсий' />
            </Helmet>
        <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >

                <div style={{ background: 'linear-gradient(180deg, #07B7AD, white)'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2} md={3}></Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <div style={{ textAlign: 'center' }}>
                                <img width="100%" height="100%" src={license} />
                                {/*<Button variant="contained" style={{ borderRadius: '60px', width: "80%", height: "100%" }} onClick={handlePaymentCreate}>Приобрести лицензию</Button>*/}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}
                              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
                        >
                            <Typography style={{ color: '#000', fontWeight: '600' }} variant={isMobile ? "h3" : "h2"}>
                                Возможности подписки
                            </Typography>
                            <Typography style={{fontWeight: '600'}} variant='h4'>
                                <Typography variant='h4' style={{ textDecoration: 'line-through', display: 'inline-block',fontWeight: '600'
                                }}>1000</Typography> 500 руб / месяц
                            </Typography>
                            <Typography  style={{ color: '#000', fontSize:'16px' }}><CheckIcon />Неограниченное количество конверсий</Typography>
                          <Typography  style={{ color: '#000', fontSize:'16px' }}><CheckIcon />Более подробная аналитика и отчеты</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={3}></Grid>
                    </Grid>
                </div>

                <div style={{ marginTop: '20px', position: 'relative'  }}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>

                        <Grid item xs={12} sm={2} md={3}></Grid>

                        <Grid item xs={12} sm={4} md={3} style={{ position: 'relative' }}>
                            {licenceCards[0]}
                        </Grid>

                        <Grid item xs={12} sm={4} md={3} style={{ position: 'relative' }}>
                            {licenceCards[1]}
                        </Grid>
                        <Grid item xs={12} sm={2} md={3}></Grid>
                    </Grid>
                </div>

                <div style={{marginTop: "50px", marginBottom:'10px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2} md={3}></Grid>
                        <Grid item xs={12} sm={3} md={6}>
                            <Typography variant='h5' style={{color:'#000', fontWeight:'600',
                                textAlign:'center'}}>Часто задаваемые вопросы</Typography>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Как купить подписку?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                               Выбирете способ оплаты (зарубежной или картой РФ), внесите оплату и используйте
                               неограниченное количество оффлайн конверсий в течение месяца
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>Какой картой я могу оплатить подписку? РФ или зарубежной?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Можете оплачивать и той и другой, в любой удобной для вас валюте.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                       <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel2-content"
                         id="panel2-header"
                    >
                    <Typography>Как отменить подписку?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Если вы захотите приостановить использование нашего приложения, просто ждете конца следующего месяца.
                            Даньги не спишутся.
                          </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <div>
                        <Typography>Остались вопросы? Напишите <NavLink style={{color:'#07B7AD'}} to='https://t.me/alice_berd'> в Telegram</NavLink></Typography>
                    </div>

                        </Grid>
                        <Grid item xs={12} sm={2} md={4}></Grid>
                    </Grid>

                </div>

            </Box>
        </>
)
}




