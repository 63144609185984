import React, {useState, useEffect} from "react";
import {color} from "framer-motion";
import Grid from "@mui/material/Grid";
import pint from "../../../public/img/03.png";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
export default function Empty() {

    return (
        // <div style={{marginTop: "200px", textAlign: 'center'}}>
        //     <h1 style={{ color: 'darkgray', textAlign:'center'}}>Здесь пока ничего нет</h1>
        //     <p style={{ color: 'darkgray', textAlign:'center'}}>Чтобы добавить проект нажмите на кнопку "добавить проект"</p>
        // </div>
        <div style={{color: 'darkgray', textAlign: 'justify', marginLeft:'1em' }} >
            <Grid container spacing={{ xs: 2, md: 3 }}  >
                <Grid item xs={12} sm={2} md={1}></Grid>
                <Grid item xs={12} sm={8} md={12}>
                    <h1 style={{fontFamily:'Montserrat'}} >Здесь пока ничего нет</h1>
                    <p style={{fontFamily:'Montserrat'}} >Чтобы добавить проект нажмите на кнопку "добавить проект"</p>
                </Grid>
                <Grid item xs={12} sm={2} md={1}></Grid>
            </Grid>
        </div>
    )
}