import React from "react";
import Grid from "@mui/material/Grid";
export default function EmptyHistory() {

    return (
        <div style={{color: 'darkgray', textAlign: 'center',fontFamily:'Montserrat'}} >
            <Grid container spacing={{ xs: 2, md: 3 }}  >
                <Grid item xs={12} sm={2} md={1}></Grid>
                <Grid item xs={12} sm={8} md={10}>
                    <h1>Список платежей пуст</h1>
                </Grid>
                <Grid item xs={12} sm={2} md={1}></Grid>
            </Grid>
        </div>
    )
}