import { siteOrigin, backendUrl } from "../globalConfig";


export const getCardConversionsFilter = async (
    cardId: number,
    conversionName: string,
    currency: string,
    clientId: string,
    beginDate: string,
    endDate: string
) => {
    const params = new URLSearchParams();
    params.append('card_id', cardId.toString());


    if (conversionName.length > 0) {
        params.append('conversion_name', conversionName);
    }


    if (currency.length > 0) {
        params.append('currency', currency);
    }


    if (beginDate.length > 0) {
        params.append('begin_date', beginDate);
    }


    if (endDate.length > 0) {
        params.append('end_date', endDate);
    }


    if (clientId.length > 0) {
        params.append('client_id', clientId);
    }


    return await fetch(backendUrl + '/conversions/get_card_conversions_filter?' + params, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    }).then(r => r.json());
};


