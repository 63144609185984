import React, {Dispatch, SetStateAction} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {IConversion} from "../../../types/redux_types";
import {deleteConversion} from "../../../asyncActions/deleteConversion";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import alert from "../../Buttons/Delete/Alert/Alert.module.css";

export interface IDeleteProps{
    id: number,

    conversionArray: IConversion[],
    setConversionArray: Dispatch<SetStateAction<IConversion[]>>,
}


const ButtonConverionTable: React.FC<IDeleteProps> = ({id, conversionArray, setConversionArray}) => {

    const [open, setOpen] = React.useState(false);


    const handleClick = async () => {
        setOpen(true);
        try {
            await deleteConversion(id);
            const updatedConversionArray = conversionArray.filter((conversion) => conversion.id !== id);
            setConversionArray(updatedConversionArray);
        } catch (error) {
            console.error("Error deleting conversion:", error);
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return(
        <Stack spacing={4} sx={{ width: '100%' }}>
            <Button className={alert.delete} onClick={handleClick} >удалить</Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '150%', height:'50%'}}>
                    Конверсия успешно удалена
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export default ButtonConverionTable