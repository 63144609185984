import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { deleteCard } from "../../../asyncActions/deleteCard";
import { useAppDispatch } from "../../../hooks/redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { IConversion } from "../../../types/redux_types";
import ButtonConverionTable from "../../ConversionTable/ButtonConverionTable/ButtonConverionTable";
import deletebutton from "../Delete/Delete.module.css";
import delete_conversion from './DeleteConversion.module.css'

interface cardProps {
    cardId: number,
    conversionArray: IConversion[],
    setConversionArray: React.Dispatch<React.SetStateAction<IConversion[]>>,
}

const DeleteConversionButton: React.FC<cardProps> = ({ cardId, conversionArray, setConversionArray }) => {
    const dispatch = useAppDispatch()

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };


    return (
        <>
            {/*<ButtonConverionTable  id={cardId} conversionArray={conversionArray}*/}
            {/*                      setConversionArray={setConversionArray}  />*/}
            <DeleteIcon onClick={handleClickOpen} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div>
                    <DialogTitle id="alert-dialog-title">{"Вы уверены, что хотите удалить эту карточку?"}</DialogTitle>
                    <Typography className={delete_conversion.par}>После удаления данные навсегда будут утеряны</Typography>
                    <DialogContent>
                        <DialogContentText>
                            <Box>
                                <div className={delete_conversion.button}>
                                    <div className={delete_conversion.but}>
                                        <ButtonConverionTable id={cardId} conversionArray={conversionArray}
                                                                       setConversionArray={setConversionArray} />
                                    </div>
                                    <div className={delete_conversion.but}>
                                        <Button onClick={handleClose}>отменить</Button>
                                    </div>
                                </div>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    )
}

export default DeleteConversionButton;