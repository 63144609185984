import React from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
export function Footer() {
    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    // minHeight: '100vh', // Uncomment and set a value if needed
                    // flexGrow: 1
                }}
            >

                <div style={{ backgroundColor: '#07B7AD', color: 'white', position: 'inherit', left: 0, bottom: 0, width: '100%'}}>
                    <Grid container spacing={{ xs: 2, md: 3 }} style={{ background: 'linear-gradient(180deg, white, #07B7AD'}}>

                        <Grid item xs={12} sm={3} md={6}>
                            <div style={{
                                marginTop: '2em',
                                marginBottom: '1em',
                                marginLeft: '2em',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                            <Typography style={{ marginBottom: '0.5em', fontWeight: 500,
                                textAlign: 'center', color:'#3f5e5d' }} variant="h6">Главное меню</Typography>
                            <NavLink style={{ marginBottom: '0.5em', color:'#3f5e5d', textDecoration: 'none' }} to='/help'>
                                Подсказка
                            </NavLink>
                            <NavLink style={{ marginBottom: '0.5em', color:'#3f5e5d', textDecoration: 'none' }} to='/termsofuse'>
                                Пользовательское соглашение
                            </NavLink>
                            <NavLink style={{ marginBottom: '0.5em', color:'#3f5e5d', textDecoration: 'none' }} to='/license'>
                                Лицензия
                            </NavLink>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={6}>
                            <div style={{
                                marginTop: '1em',
                                marginBottom: '1em',
                                marginLeft: '2em',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                            <Typography style={{ marginBottom: '0.5em', fontWeight: 500,
                                textAlign: 'center', color:'#3f5e5d' }} variant="h6">Контакты</Typography>
                            {/*<Typography style={{ marginBottom: '0.5em', color:'#3f5e5d' }}>*/}
                            {/*    +357-95-56-36-54 Кипр*/}
                            {/*</Typography>*/}
                            {/*<Typography style={{ marginBottom: '0.5em', color:'#3f5e5d' }}>*/}
                            {/*    +7-963-24-97-436 Россия*/}
                            {/*</Typography>*/}
                            <Typography style={{ marginBottom: '0.5em', color:'#3f5e5d' }}>
                                alisa@digitalberd.com
                            </Typography>
                            <NavLink style={{ marginBottom: '0.5em', color:'#3f5e5d', textDecoration: 'none' }} to='https://t.me/+76LDbKMIDL04NDcy'>
                                Задать вопрос в Telegram
                            </NavLink>
                            </div>
                        </Grid>

                        <div style={{
                            marginLeft: '2em', color:'#3f5e5d'
                        }} >
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} sm={3} md={12}>
                                    <p>&copy; {new Date().getFullYear()} Все права защищены</p>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </Box>
        </>
    );
}
