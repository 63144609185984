import React, { useEffect } from "react";
import mycarts from './MyCarts.module.css';
import Cart from "../../Components/Cart/Cart";
import AddButton from "../../Components/Buttons/Add/Add";
import Empty from "../../Components/Empty/Empty";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { cardsSlice } from "../../redux/cardReducer";
import { getCard } from "../../asyncActions/getCard";
import { CssBaseline, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import AddManyConversion from "../../Components/Buttons/AddManyConverion/AddManyConversion";
import {Helmet} from "react-helmet-async";
import {getUser} from "../../asyncActions/get_user";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
export default function MyCartsPage() {
    const dispatch = useAppDispatch();
    const user_email = useAppSelector(state => state.user.email);
    const { cardsFetching } = cardsSlice.actions;
    const cardArray = useAppSelector((state) => state.card.cards_array);


    useEffect(() => {
        dispatch(getCard());
        dispatch(getUser());
    }, []);


    return (
        <>
            <Helmet>
                <title>Мои проекты Conversion Arrow</title>
            </Helmet>
            <CssBaseline />
            <div style={{ marginTop: '16px',textAlign: 'justify', fontFamily:'Montserrat', marginLeft:'1em'  }}>
                <AddButton />
            </div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >


                <div style={{ marginLeft: '1em', marginTop:'1em', marginBottom:'1em'}}>
                    <Grid container spacing={{ xs: 2, md: 3 }}
                    >
                        {cardArray.length > 0 ? (
                            cardArray.map((e) => (
                                <Grid item xs={12} sm={6} md={4} key={e.id}>
                                    <Cart
                                        card_type={e.card_type}
                                        name={e.name}
                                        api_key={e.api_key}
                                        url={e.url}
                                        stream_id={e.stream_id}
                                        measurement_id={e.measurement_id}
                                        modify_date={e.modify_date}
                                        create_date={e.create_date}
                                        id={e.id}
                                        user_id={e.user_id}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <Empty />
                        )}
                    </Grid>
                </div>
            </Box>
        </>
    );
}


