import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILoginUser } from "../types/redux_types";

const initialState: ILoginUser = {
    id: "",
    email: "",
    license_id: 0,
    license_name: "",
    license_end_datetime: "",
    conversions_limit: 0,
    isError: false,
    nextPage: "",
    isNeedsNavigate: 0
};

export const LoginSlice = createSlice({
    name: "namexxx@mail.com",
    initialState,
    reducers: {
        redux_login(state, action: PayloadAction<ILoginUser>) {
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.license_id = action.payload.license_id;
            state.license_name = action.payload.license_name;
            state.license_end_datetime = action.payload.license_end_datetime;
            state.conversions_limit = action.payload.conversions_limit;
            state.isError = true;
        },
        redux_logout(state) {
            state.id = "";
            state.email = "";
            state.license_id = 0;
            state.license_name = "";
            state.license_end_datetime = "";
            state.conversions_limit = 0;
            state.isError = true;
        },
        setLoginError(state) {
            state.isError = true;
        },
        setNextPage(state, action: PayloadAction<string>) {
            state.nextPage = action.payload;
        },
        setNeedsNavigate(state, action: PayloadAction<number>) {
            state.isNeedsNavigate = action.payload;
        },
    },
});

export default LoginSlice.reducer;
