import React, { ReactNode, useEffect, useState } from "react";
import ConverisonTable from "../../Components/ConversionTable/ConverisonTable";
import AddConversion from "../../Components/Buttons/AddConversion/AddConversion";
import { IConversion } from "../../types/redux_types";
import { NavLink, useParams } from "react-router-dom";
import { getConversion } from "../../asyncActions/getConversion";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { CssBaseline } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Helmet } from "react-helmet-async";
import {backendUrl} from "../../globalConfig";
import Button from "@mui/material/Button";
import {FileUploader} from "react-drag-drop-files";
import {getUser} from "../../asyncActions/get_user";
import EmptyConversion from "../../Components/Empty/EmptyConversion";
import SearchComponent from "../../Components/SearchComponent/SearchComponent";
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

export default function ConversionsPage ()  {
    const dispatch = useAppDispatch();
    const user_data = useAppSelector(state => state.user.conversions_limit);
    const license_id = useAppSelector(state => state.user.license_id);
    const [conversionArray, setConversionArray] = useState<IConversion[]>([]);
    const [click, setClick] = React.useState(0)
    const params = useParams();
    const [showAlert, setShowAlert] = React.useState(false);

    const fileTypes = ["csv"];

    const handleUploadFile = async (file: any) => {
        var apiUrl = backendUrl + '/conversions/upload_csv?card_id=' + Number(params.cardId).toString();
        const formData = new FormData();
        formData.append("csv_file", file);


        const newConversions = await fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        })
            .then(response => response.json())
        if (newConversions != null ) {
            setConversionArray([...conversionArray, ...newConversions]);
        }
    };


    useEffect(() => {
        dispatch(getUser());
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const data = await getConversion(Number(params.cardId));
            console.log("Fetched data:", data);
            setConversionArray(data);
        };
        fetchData();
    }, []);


    useEffect(() => {
        if (license_id === 1 && click >=5) {
            setShowAlert(true);
        }
    }, [click, license_id]);


    return (
        <>
            <Helmet>
                <title>Мои конверсии Conversion Arrow</title>
            </Helmet>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >
                <div style={{ marginTop: '20px' }}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>


                        <Grid item xs={12} sm={6} md={4}>
                            <div style={{ marginTop: '1em', marginBottom: '1em', marginLeft: '1em', display: 'flex',
                                flexDirection: 'column', alignItems: 'flex-start'}}>
                                <AddConversion cardId={Number(params.cardId)}
                                               conversionArray={conversionArray} setConversionArray={setConversionArray} />
                            </div>


                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button
                                style={{color: "white", marginLeft: '1em',
                                    marginTop: '1em',borderRadius:'20px', fontFamily:'Montserrat',
                                    background: 'linear-gradient(180deg, #26DDD4, #07B7AD)' }}
                                variant="contained"
                                component={NavLink}
                                to="https://api.conversion-arrow.com/conversions/file_example"
                            >Пример файла CSV</Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ borderRadius: 2, bgcolor: 'background.paper',  marginTop:'1em' }}>
                                <FileUploader handleChange={handleUploadFile} name="file" types={fileTypes}/>
                            </Box>
                        </Grid>


                    </Grid>
                </div>

                <SearchComponent conversionArray={conversionArray} setConversionArray={setConversionArray} />

                <div style={{margin: "16px"}}>
                    <Divider></Divider>
                </div>
                <div style={{marginTop: '16px'}}>
                    {conversionArray.length > 0 ? (
                        <ConverisonTable conversionArray={conversionArray} setConversionArray={setConversionArray} />
                    ) : (
                        <EmptyConversion/>
                    )}
                </div>
            </Box>
        </>
    );
}




