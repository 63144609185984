import React from 'react';
import Typography from "@mui/material/Typography";
import { projectData } from "./termsOfUseJSON";
import {CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Helmet} from "react-helmet-async";

interface Element {
    text: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
}

interface Project {
    head: string;
    elements_array: Element[];
}

const TermsOfUse: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Руководство пользователя Conversion Arrow</title>
                <meta name='description' content='оффлайн конверсия, оффлайн событие, эффективные продажи, добавление конверсий,
                 Google Analytics, Гугл аналитика, маркетинг, товары в интернете, услуги в интернете, лицензирование для оффлайн конверсий'/>
            </Helmet>
            <CssBaseline />
            <Box
                sx={{
                    // display: 'grid',
                    // flexDirection: 'column',
                    // minHeight: '100vh',
                    // flexGrow: 1
                }}
            >

           <div style={{marginTop: "16px", textAlign: 'left'}}>
               <Grid container spacing={{ xs: 2, md: 3 }} >
                   <Grid item xs={1} sm={2} md={2}></Grid>
                   <Grid item xs={10} sm={3} md={8}>
                <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant="h3" component="div">Условия использования</Typography>
                <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant='body1' component='div'>Проставляя отметку в поле «Я согласен», сопровождающемся ссылкой на настоящую Политику,
                    вы (далее также — пользователь) свободно, своей волей и в своих интересах даете свое
                    письменное согласие на обработку Персональной информации в порядке и на условиях, установленных настоящей Политикой.</Typography>
                {projectData.map((project: Project) => (
                    <div  key={project.head}>
                        <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant="h5" component="div">{project.head}</Typography>
                        {project.elements_array.map((element: Element, index: number) => (
                            <div key={index}>
                                <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant="body1">{element.text}</Typography>
                                <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant="body1">{element.text1}</Typography>
                                <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant="body1">{element.text2}</Typography>
                                <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant="body1">{element.text3}</Typography>
                                <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant="body1">{element.text4}</Typography>
                                <Typography style={{fontFamily:'Montserrat', marginTop: "5px"}} variant="body1">{element.text5}</Typography>
                            </div>
                        ))}
                    </div>
                ))}
                   </Grid>
                   <Grid item xs={2} sm={2} md={2}></Grid>
               </Grid>
           </div>
            </Box>
        </>
    );
};

export default TermsOfUse;