import React from 'react';
import {CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet-async";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenyTytorial from "../menyTytorial/MenyTytorial";
import {tutorialJSON} from "../tutorialJSON";
import TutorialBlock from "../../../Components/TutorialBlock/TutorialBlock";

export default function ClientId ()  {

    const tutorialBlock: JSX.Element[] = tutorialJSON.map((e, index) => (
        <TutorialBlock
            key={index}
            headline={e.headline}
            description={e.description}
            video={e.video}
        />
    ));

    return (
        <>
            <Helmet>
                <title>Документация Conversion Arrow</title>
                <meta name='description' content='оффлайн конверсия, google client id, Google Analytics,
                    Гугл аналитика, интернет-маркетинг, маркетинг, товары в интернете, услуги в интернете,
                    типы конверсий' />
            </Helmet>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    minHeight: '100vh'
                }}
            >

                <Grid item xs={12} sm={2} md={4}>
                    <MenyTytorial/>
                </Grid>

                {tutorialBlock[4]}

            </Box>
        </>
    );
}