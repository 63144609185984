import {siteOrigin, backendUrl} from "../globalConfig"

export const insertConversion = async (card_id: number, client_id: string,
                                    conversion_name: string, conversion_value: string, currency:string) => {
    return await fetch(backendUrl+'/conversions/conversion_insert', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            card_id: card_id,
            client_id: client_id,
            conversion_name: conversion_name,
            conversion_value: conversion_value,
            currency: currency
        })
    })

        .then(r => r.json())
    // return resultArray
}
