import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IConversion } from '../../types/redux_types';
import DeleteConversionButton from '../Buttons/DeleteConversion/DeleteConversionButton';
import { CSVLink } from 'react-csv';
import Button from "@mui/material/Button";

export interface IConversionProps {
    conversionArray: IConversion[];
    setConversionArray: Dispatch<SetStateAction<IConversion[]>>;
}

const ConversionTable: React.FC<IConversionProps> = ({ conversionArray, setConversionArray }) => {
    const csvData = [
        ["Название", "Валюта", "Дата создания", "Client ID"],
        ...conversionArray.map(({ conversion_name, conversion_value, create_date, client_id }) => [
            conversion_name,
            conversion_value,
            create_date,
            client_id,
        ]),
    ];

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                Название
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                Значение конверсии
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                Валюта
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                Дата создания
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                Client id
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {conversionArray.map((e) => (
                            <TableRow key={e.id}>
                                <TableCell align="center">{e.conversion_name}</TableCell>
                                <TableCell align="center">{e.conversion_value}</TableCell>
                                <TableCell align="center">{e.currency}</TableCell>
                                <TableCell align="center">{e.create_date}</TableCell>
                                <TableCell align="center">{e.client_id}</TableCell>
                                <DeleteConversionButton
                                    key={`DeleteConversionButton${e.id}`}
                                    cardId={e.id}
                                    conversionArray={conversionArray}
                                    setConversionArray={setConversionArray}
                                />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button style={{ marginLeft: '2em', marginTop: '1em', marginBottom: '1em',
                borderRadius:'20px', fontFamily:'Montserrat', background: 'linear-gradient(180deg, #26DDD4, #07B7AD)'}}
                    variant="contained">
                <CSVLink
                    style={{ color: '#fff', textDecoration: 'none' }}
                    className="downloadbtn"
                    filename="conversion_table.csv"
                    data={csvData}
                >
                    Сформировать в CSV файл
                </CSVLink>
            </Button>
        </>
    );
};

export default ConversionTable;

