// import database from '../../../public/img/01.gif'
// import sentiment_very_satisfied from '../../../public/img/02.gif'
// import rocket from '../../../public/img/rocket.png'
let database = "/img/5.png"
let sentiment_very_satisfied = '/img/6.png'
let rocket = '/img/rocket.png'
export const StrongsJSON: {
    img: string;
    elements_array: { head: string, text: string, }[];
}[] = [
    {
        img: database,
        elements_array: [
            {
                head: 'Не нужно ломать голову с инструментами гугла',
                text: 'Забудьте про бесконечные танцы с бубном с инструментами Google Analytics. ' +
                    'С Conversion Arrow, вы загрузите все необходимые данные в удобном и понятном формате, ' +
                    'без лишних сложностей и затрат времени. ',
            }
        ]
    },
    {
        img: sentiment_very_satisfied,
        elements_array: [
            {
                head: 'Не нужно быть программистом',
                text: 'Не надо учить Python и Java. Мы уже написали код и создали интуитивно понятный интерфейс, в котором ' +
                    'раберется даже новичок.'
            }
        ]
    },
    {
        img: rocket,
        elements_array: [
            {
                head: '4 шага и все готово',
                text: 'Подключаете аналитику к сайту, регистрируетесь в Conversion Arrow, заполняете поля необходимыми ' +
                    'данными и через 24 часа оффлайн конверсия в Google Analytics!',
            }
        ]
    },
]