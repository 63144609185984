import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICardDataState, ICardData} from "../types/redux_types";

export const initialState: ICardDataState = {
    cards_array: []
 }

export const cardsSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        cardsFetching(state, action: PayloadAction<ICardData[]>) {
            state.cards_array = action.payload;
        },
        cardsAddOne(state, action: PayloadAction<ICardData>) {
            state.cards_array = [...state.cards_array, action.payload];
        },
        cardDeleteOne(state, action: PayloadAction<number>) {
            const cardIndex = state.cards_array.findIndex(card => card.id === action.payload);

            if (cardIndex !== -1) {
                state.cards_array.splice(cardIndex, 1);
            }
        },
        cardUpdate(state, action: PayloadAction<ICardData>) {
            const updatedCardIndex = state.cards_array.findIndex(card => card.id === action.payload.id);

            if (updatedCardIndex !== -1) {
                state.cards_array.splice(updatedCardIndex, 1);
                state.cards_array = [...state.cards_array, action.payload];
            }
        },
    },
});

export default cardsSlice.reducer;