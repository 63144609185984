import {backendUrl} from "../globalConfig";
import {getCard} from "./getCard";
import {useAppDispatch} from "../hooks/redux";
import {AppDispatch} from "../redux/store";
import {LoginSlice} from "../redux/loginReducer";
import {cardsSlice} from "../redux/cardReducer";

export const insertNewCard = (name: string, api_key: string , measurement_id: string, url: string) => async (dispatch: AppDispatch) => {
    fetch(backendUrl + '/card/card_insert', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            card_type: 1,
            name: name,
            api_key: api_key,
            measurement_id: measurement_id,
            url: url
        })
    })
        .then((r) =>  {
            if(r.status==200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
        .then((data) => {
            if (data.status ==200){
                dispatch(cardsSlice.actions.cardsAddOne(data.json))
            }
        })
        .catch((e) => {
            console.log('Error: ' + e.message);
        })
}

