import { siteOrigin, backendUrl } from "../globalConfig";

export const getCardConversionsOneFieldFilter = async (
    cardId: number,
    stringForFilter: string
) => {
    const params = new URLSearchParams();
    params.append('card_id', cardId.toString());

    if (stringForFilter.length > 0) {
        params.append('string_for_filter', stringForFilter);
    }

    return await fetch(backendUrl + '/conversions/get_card_conversions_one_field_filter?' + params, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    }).then(r => r.json());
};

