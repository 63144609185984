import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "../../pages/login/Login";
import RegisterPage from "../../pages/register/Register";
import MainPage from "../../pages/main/Main";
import Tutorial from "../../pages/tutorial/Tutorial";
import MyCartsPage from "../../pages/my_carts/MyCarts";
import TermsOfUse from "../../pages/termsOfUse/termsOfUse";
import License from "../../pages/license/license";
import ConversionsPage from "../../pages/сonversions/Conversions";
import HistoryPage from "../../pages/history/History";
import Api_key from "../../pages/tutorial/api_key/Api_key";
import ClientId from "../../pages/tutorial/clientId/ClientId";
import Measurmente_id from "../../pages/tutorial/measurment_id/Measurmente_id";
import ErrorPage from "../../pages/error/Error";

export default function PrivateRouterPage() {
    return (
        <>
            <Routes>
                <Route path='/' element={<MainPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path='/help/*' element={<TutorialRouter />} />
                <Route path='/my_projects' element={<MyCartsPage />} />
                <Route path='/conversions/:cardId' element={<ConversionsPage />} />
                <Route path='/termsofuse' element={<TermsOfUse />} />
                <Route path='/license' element={<License />} />
                <Route path='/payments' element={<HistoryPage />} />
                <Route path="*" element={ <ErrorPage />}/>
            </Routes>
        </>
    );
}

function TutorialRouter() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Tutorial />} />
                <Route path="/api_key" element={<Api_key />} />
                <Route path="/client_id" element={<ClientId />} />
                <Route path="/measurement_id" element={<Measurmente_id />} />
                <Route path="*" element={<Navigate to="/help" />} />
            </Routes>
        </>
    );
}
