import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { insertPayment } from "../../asyncActions/insertPayment";
import { ReactNode, useState } from "react";
import { useAppSelector } from "../../hooks/redux";
import { NavLink } from "react-router-dom";
import {insertPaymentStripe} from "../../asyncActions/insertPaymentStripe";

interface Ilicence {
    img: string;
    tariff: string;
    description: string;
    price: string;
    paymentLink: string;
}

const LicenceCard: React.FC<Ilicence> = ({ tariff, description, price, paymentLink }) => {
    const user_email = useAppSelector(state => state.user.email);

    const handlePaymentCreateRus = async () => {
        const newPayment = await insertPayment(500.00, paymentLink, 'производим платеж');
        window.location.href = newPayment.redirect_link;
    };

    const handlePaymentCreateNotRus = async () => {
        const newPayment = await insertPaymentStripe();
        window.location.href = newPayment.redirect_link;
    };

    const handlePaymentCreate = tariff === 'Карта РФ' ? handlePaymentCreateRus : handlePaymentCreateNotRus;

    let v_button: ReactNode | null = null;
    if (user_email ) {
        v_button = <Button style={{ backgroundColor: '#3f5e5d', borderRadius: 20, opacity:0.7, color: '#fff'}}
                           variant="outlined" onClick={handlePaymentCreate}>Оплатить</Button>;
    } else {
        v_button = <Button style={{ backgroundColor: '#3f5e5d', borderRadius: 20, opacity:0.9}} variant="outlined">
            <NavLink style={{ textDecoration: 'none', color: '#fff' }} to='/register'>Оплатить</NavLink></Button>;
    }


    return (
        <div>
            <Box display="flex" justifyContent="center">
                <Card style={{ borderRadius: 10, textAlign: 'left', width: '475px',
                    background: 'linear-gradient(180deg, #26DDD4, #07B7AD)'}} variant="outlined">
                    <CardContent>
                        <Typography style={{ color: '#fff', fontWeight: '600', fontFamily: 'Jura' }} variant="h4">
                            {tariff}
                        </Typography>
                        <Typography style={{ color: '#fff', fontWeight: '500', fontFamily: 'Montserrat' }}
                                    sx={{ mb: 1.5 }} variant="h6">
                            {price}
                        </Typography>
                        <Typography style={{ fontFamily: 'Montserrat', color: '#fff' }} sx={{ mb: 1.5 }} variant="body2">
                            {description}
                        </Typography>
                        {v_button}
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};

export default LicenceCard;