import {siteOrigin, backendUrl} from "../globalConfig";
import {cardsSlice} from "../redux/cardReducer";
import {AppDispatch} from "../redux/store";

export const deleteCard = (cardId : number) => async (dispatch: AppDispatch) => {
    fetch(backendUrl + '/card/card_delete/' + cardId.toString(), {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then((r) =>  {
            if(r.status==200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
        .then((data) => {
            if (data.status ==200){
                dispatch(cardsSlice.actions.cardDeleteOne(cardId))
            }
        })

}
