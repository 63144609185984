export const projectData:{
    head: string;
    elements_array: { text: string, text1: string, text2: string, text3: string, text4: string,
        text5: string,
    }[];
}[] = [
    {
        head: "1. Общие положения",
        elements_array: [
            {
                text: "1.1 Настоящая Политика является неотъемлемой частью Пользовательского соглашения (далее — «Соглашение»)," +
                    " размещенного и/или доступного в сети Интернет по адресу: https://conversion-arrow.com, " +
                    "а также иных заключаемых с Пользователем договоров, когда это прямо предусмотрено их условиями. " +
                    "Таким образом, заключая Соглашение и указанные договоры установленным в них способом, " +
                    "включая использование Приложения, вы принимаете условия настоящей Политики в полном объеме.",

                text1: '1.2 Способы обработки Персональной информации включают любые действия ' +
                    '(операции) или комбинацию действий (операций), в том числе, сбор, запись, ' +
                    'систематизация, хранение, уточнение (обновление, изменение), извлечение,' +
                    ' использование, передачу (предоставление, доступ), обезличивание, блокирование,' +
                    'удаление, уничтожение в установленных настоящей Политикой целях с использованием ' +
                    'средств автоматизации или без использования таких средств по усмотрению Conversion Arrow',

                text2: '1.3 Здесь и далее в Политике используются термины ' +
                    'и определения, предусмотренные Соглашением,' +
                    ' а также иными заключаемыми с Пользователем договорами,' +
                    ' если иное не предусмотрено настоящей Политикой или не ' +
                    'вытекает из ее существа. В иных случаях толкование применяемого в ' +
                    'Политике термина производится в соответствии с применимым законодательством, ' +
                    'обычаями делового оборота, или научной доктриной.',
                text3: '',
                text4: '',
                text5: '',
            },
        ]
    },
    {
        head: "2. Персональная информация",
        elements_array: [
            {
                text: "2.1 Под Персональной информацией в настоящей Политике понимается:\n" +
                    "\n" +
                    "2.1.1 Информация, которую Пользователь предоставляет о себе самостоятельно при регистрации" +
                    " или авторизации, а также в процессе дальнейшего использования Приложения и/или Сервиса," +
                    " включая персональные данные Пользователя.\n" +
                    "2.1.2 Данные, которые передаются в автоматическом режиме в зависимости " +
                    "от настроек программного обеспечения Пользователя в обезличенном виде.",
                text1: '2.2 Conversion Arrow вправе устанавливать требования к составу Персональной информации Пользователя,' +
                    ' которая должна обязательно предоставляться для использования Приложения и основанного на нем ' +
                    'Сервиса. Если определенная информация не помечена Conversion Arrow как обязательная, ее предоставление или' +
                    ' раскрытие осуществляется Пользователем по своему усмотрению.',
                text2: '2.3 При регистрации Пользователем обязательно указываются имя учётной записи (логин), адрес электронной почты и пароль.\n' +
                    'При регистрации Conversion Arrow создает уникальный идентификатор каждого Пользователя (user_id).' +
                    ' Идентификатор Пользователя привязан к информации профиля Пользователя.',
                text3: '2.4 Conversion Arrow не осуществляет проверку достоверности предоставляемой Персональной информации' +
                    ' и наличия у Пользователя необходимого согласия на ее обработку в соответствии с настоящей ' +
                    'Политикой, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые' +
                    ' усилия к поддержанию такой информации в актуальном состоянии и получению всех необходимых согласий ' +
                    'субъектов персональных данных.',
                text4: '2.5 Conversion Arrow осуществляет сбор и хранение данных о действиях Пользователя в Сервисе, используя при этом файлы журналов.' +
                    ' В случае, если действия совершены авторизованным Пользователем, такая информация связывается с ' +
                    'идентификатором Пользователя.',
                text5: '2.6 Conversion Arrow не несет ответственность за порядок использования Персональной информации Пользователя' +
                    ' третьими лицами, с которыми Пользователь' +
                    ' взаимодействует в рамках использования приложения',
            },

        ]
    },
    {
        head: "3. Правовые основания обработки Персональной информации",
        elements_array: [
            {
                text: "3.1 Правовые основания, на которых Conversion Arrow использует Персональную информацию, включают:\n" +
                    "\n" +
                    "3.1.1 Обработку Персональной информации, когда Пользователь предоставил согласие на обработку для одной или нескольких конкретных целей;\n" +
                    "3.1.2 Обработку Персональной информации, когда это необходимо для исполнения договора, стороной которого является Пользователь;\n" +
                    "3.1.3 Обработку Персональной информации, когда это необходимо для заключения договора с Пользователем;\n" +
                    "3.1.4 Обработку Персональной информации, которая необходима для исполнения Conversion Arrow своих обязательств в соответствии с применимым правом.",
                text1: '',
                text2: '',
                text3: '',
                text4: '',
                text5: '',
            },

        ]
    },
    {
        head: "4. Цели обработки Персональной информации",
        elements_array: [
            {
                text: "4.1 Conversion Arrow осуществляет обработку, в том числе сбор и хранение той Персональной информации ",
                text1: 'Хабр вправе использовать Персональную информацию в следующих целях:\n' +
                    '\n' +
                    '4.1.1 Заключение договоров на использование приложения и Сервиса; Заключение Договора осуществляется в дистанционном порядке посредством обмена электронными документами, которые подписываются простой электронной подписью. При этом роль ключа простой электронной подписи Пользователя могут выполнять пара логин — пароль или адрес его электронной почты. Поэтому они запрашиваются при регистрации.\n' +
                    '4.1.2 Исполнение обязательств по заключенным договорам, включая предоставление Пользователю доступа к Приложению и Сервису; Заявки на предоставление доступа к Сервису принимаются с использованием программных средств Приложения и электронной почты. В рамках реализации функциональных возможностей Сервиса Правообладатель хранит, систематизирует и отображает в Приложении профили Пользователей, содержащие Персональную информацию.\n' +
                    '4.1.3 Оказание технической поддержки в связи с использованием приложени; Обращения в службу поддержки принимаются с использованием программных средств приложения и электронной почты.\n',
                text2: '',
                text3: '',
                text4: '',
                text5: '',
            },

        ]
    },
    {
        head: "5. Права Пользователя в отношении Персональной информации",
        elements_array: [
            {
                text: "4.1 Conversion Arrow осуществляет обработку, в том числе сбор и хранение той Персональной информации ",
                text1: 'Хабр вправе использовать Персональную информацию в следующих целях:\n' +
                    '\n' +
                    '4.1.1 Заключение договоров на использование приложения и Сервиса; Заключение Договора осуществляется в дистанционном порядке посредством обмена электронными документами, которые подписываются простой электронной подписью. При этом роль ключа простой электронной подписи Пользователя могут выполнять пара логин — пароль или адрес его электронной почты. Поэтому они запрашиваются при регистрации.\n' +
                    '4.1.2 Исполнение обязательств по заключенным договорам, включая предоставление Пользователю доступа к Приложению и Сервису; Заявки на предоставление доступа к Сервису принимаются с использованием программных средств Приложения и электронной почты. В рамках реализации функциональных возможностей Сервиса Правообладатель хранит, систематизирует и отображает в Приложении профили Пользователей, содержащие Персональную информацию.\n' +
                    '4.1.3 Оказание технической поддержки в связи с использованием приложени; Обращения в службу поддержки принимаются с использованием программных средств приложения и электронной почты.\n',
                text2: '',
                text3: '',
                text4: '',
                text5: '',
            },

        ]
    },
    {
        head: "6. Хранение Персональной информации",
        elements_array: [
            {
                text: "Вся собранная Персональная информация хранится только в течение периода, когда она необходима" +
                    " для достижения целей, в которых такая информация была собрана, или в течение периода, разрешенного " +
                    "или требуемого в соответствии с положениями применимого законодательства. Периодически Conversion Arrow проводит " +
                    "проверку систем обработки данных, для определения того, остаются ли действительными цели сбора и обработки" +
                    " персональных данных пользователей. Такое определение основано на ряде факторов, который включает, но не " +
                    "ограничивается следующим: поддерживает ли Пользователь отношения с Conversion Arrow, выполнены ли Conversion Arrow запросы" +
                    " Пользователя (включая любые дополнительные задания), существуют ли договорные отношения между " +
                    "Пользователем и Conversion Arrow, а также существуют ли договорные или законные основания для продолжения хранения " +
                    "персональных данных Пользователя. Основываясь на результатах такой проверки и уведомлениях, полученных от" +
                    " субъектов персональных данных, Conversion Arrow обновляет свои системы.",

                text1: 'В целях повышения качества Сервиса и обеспечения возможности правовой защиты, Conversion Arrow вправе хранить ' +
                    'лог-файлы о действиях, совершенных Пользователем в рамках использования приложения, а также в связи с' +
                    ' заключением и исполнением Пользователем Соглашения и иных договоров со своей стороны, в течение 1  (Одного) года с момента их совершения.',

                text2: '',
                text3: '',
                text4: '',
                text5: '',
            },

        ]
    },
];