import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Header } from "./Components/Header/Header";
import PrivateRouterPage from "./Components/privateRouter/privateRouter";
import { Footer } from "./Components/Footer/Footer";

function App() {
    const helmetContext = {}; // Define your helmetContext here

    return (
        <BrowserRouter>
            <HelmetProvider context={helmetContext}>
                <div className="App">
                    <header>
                        <Header />
                    </header>
                    {/*<Test/>*/}
                    <PrivateRouterPage />
                    <Footer />
                </div>
            </HelmetProvider>
        </BrowserRouter>
    );
}

export default App;
