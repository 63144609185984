import loginReducer from "./loginReducer";
import cardReducer from './cardReducer'
import paymentReducer from "./paymentReducer";
import {configureStore, combineReducers} from "@reduxjs/toolkit";

const rootReducer = combineReducers({
    user: loginReducer,
    card: cardReducer,
    payment: paymentReducer,
})
export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
}

//export const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)))
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>

export type AppDispatch = AppStore['dispatch']

