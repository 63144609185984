import React, { useState, useEffect, ChangeEvent } from 'react';
import { Tabs, Tab, IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { NavLink, useLocation } from "react-router-dom";

const MenyTytorial = () => {
    const [value, setValue] = useState(0);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600); // Проверка ширины экрана при загрузке страницы
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const handleResize = () => {
        setIsDesktop(window.innerWidth > 600); // Обновление состояния isDesktop при изменении ширины экрана
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize); // Подписка на событие изменения размера окна
        return () => {
            window.removeEventListener("resize", handleResize); // Отписка от события при размонтировании компонента
        };
    }, []);

    useEffect(() => {
        const tabPaths = ["/help/tutorial", "/help/api_key", "/help/client_id", "/help/measurement_id"];
        const currentTab = tabPaths.indexOf(location.pathname);

        setValue(currentTab !== -1 ? currentTab : 0);
    }, [location.pathname]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            {/* Кнопка бургера для мобильных устройств */}
            {!isDesktop && (
                <IconButton
                    onClick={toggleMenu}
                    sx={{ ml: -1, mr: 2 }}
                    aria-label="open drawer"
                    edge="start"
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
            )}

            {/* Отображение меню */}
            <Tabs
                style={{ display: isDesktop || isMenuOpen ? 'block' : 'none' }} // Показывать меню на десктопе или при открытом состоянии isMenuOpen
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Основное" to="/help" component={NavLink} />
                <Tab label="API Key" to="/help/api_key" component={NavLink} />
                <Tab label="Client ID" to="/help/client_id" component={NavLink} />
                <Tab label="Measurement ID" to="/help/measurement_id" component={NavLink} />
            </Tabs>
        </>
    );
};

export default MenyTytorial;
