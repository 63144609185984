import React from 'react';
import {CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet-async";
import Grid from "@mui/material/Grid";
// import error from "../../../public/img/404.png";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {NavLink} from "react-router-dom";

export default function ErrorPage() {
    let error = "/img/404.png"

    return (
        <>
            <Helmet>
                <title>404</title>
            </Helmet>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >
                <div style={{marginTop: "16px", textAlign: 'center'}} >
                    <Grid container spacing={{ xs: 2, md: 3 }}  >
                        <Grid item xs={12} sm={2} md={3}></Grid>
                        <Grid item xs={12} sm={8} md={6}
                        >
                            <img style={{textAlign: 'center'}} src={error} />
                            <Typography style={{fontWeight: 700,
                                textAlign: 'center', color:'#07B7AD', fontFamily:'Montserrat'}} variant="h1">
                                Упппс!!!
                            </Typography>
                            <Typography style={{fontWeight: 400,
                                textAlign: 'center', color:'#07B7AD', fontFamily:'Montserrat'}} variant="h6">
                                Что-то пошло не так! Вернитесь на главную страницу!
                            </Typography>
                            <Button style={{ borderColor: '#07B7AD', fontFamily:'Montserrat', borderRadius:'5px'}}  variant="outlined">
                                <NavLink style={{ color: '#07B7AD', textDecoration: 'none'}} to="/">
                                Перейти на главную </NavLink>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={2} md={3}></Grid>
                    </Grid>
                </div>

            </Box>
        </>
    );
}