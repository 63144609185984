import {siteOrigin, backendUrl} from "../globalConfig"
;

export const insertPayment = async (amount: number, return_url: string, description: string) => {
    return await fetch(backendUrl+'/payments/create_new_payment', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            amount: amount,
            return_url: return_url,
            description: description,
        })
    })

        .then(r => r.json())
}